import React from 'react';

export default function HomeContent() {
  return (
    <section className="container">
      <div className="columns features">
        <div className="column is-4">
          <div className="card is-shady">
            <div className="card-content">
              <div className="content">
                <h4>Become an AWS Authorized Instructor</h4>
                <p>The AWS Authorized Instructor (AAI) Program is a global program that comprises instructors authorized to deliver official AWS curriculum. This includes AWS employees, partner-employed instructors and freelance instructors.</p>
                <p><a href="https://aws.amazon.com/training/aws-authorized-instructor-program/">Learn more about the AAI Program</a></p>
              </div>
            </div>
          </div>
        </div>
        <div className="column is-4">
          <div className="card is-shady">
            <div className="card-content">
              <div className="content">
                <h4>Who is invited to the event?</h4>
                <p>This is an exclusive, global event for AWS Authorized Instructors (AAIs) and our AWS Training Partner community.</p>
              </div>
            </div>
          </div>
        </div>
        <div className="column is-4">
          <div className="card is-shady">
            <div className="card-content">
              <div className="content">
                <h4>Registering for the event</h4>
                <p>AWS Training Instructor & Partner Summit is your annual opportunity to gather key insights from the AWS Training business, learn about what’s coming for the rest of 2022, and engage with other members of the gobal AAI community.</p>
                <p>Check your email for registration invite.</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <section className="container">
      <div className="columns features">
        <div className="column is-half">
          <div className="card equal-height">
            <div className="card-content is-flex is-horizontal-center">
            <img src="deepracer-car.png" width="318" height="222" alt="AWS Deepracer" />
            </div>
            <div className="card-content">
              <div className="content">
                <h3 className='is-3'>Instructors, start your engines!</h3>
                <p>Are you looking for a fun way of getting started with machine learning through hands on experience? Or maybe you just want to apply some of your ML skills to a fun and interactive competition with other AWS Training colleagues? If you answered ‘yes’ to either of those questioons, then consider participating in this year’s <strong>AAI DeepRacer Challenge</strong>. <a href="https://aws.amazon.com/deepracer/" target="_blank" rel="noreferrer">AWS DeepRacer</a> helps builders of all levels get hands on with machine learning through a cloud based 3D racing simulator. Throughout this year’s AWS TIPS event, you can  participate in the AAI DeepRacer Challenge at any time.</p>
                <p><strong>Here’s how to get started:</strong></p>
              
                <p>1. If you’re new to machine learning and/or AWS DeepRacer, then take some time to <a href="https://www.youtube.com/watch?v=VKenu-Fm--g" target="_blank" rel="noreferrer">watch this pre-competition recording </a>that will help you build the fundamental knowledge you’ll need to get started quickly with AWS DeepRacer.</p>
                <p>2. <a href="https://us-east-1.console.aws.amazon.com/deepracer/home#raceToken/jUSUXXcaReCBJ7053E-xDQ" target="_blank" rel="noreferrer">Sign into the AWS DeepRacer console</a> and get started building and training your AWS DeepRacer model.</p>
                <p>3. Submit your model time to the AAI DeepRacer Challenge Scoreboard. See where your time compares to times submitted by other AWS TIPS participants.</p>
                <p>4. Try again! You can submit as many race times to the scoreboard as you’d like throughout the 3 day event. We’ll announce the winners of the challenge at the end of AWS TIPS, with extra fun swag for the winners!</p>

                </div>
              </div>


          </div>


        </div>
        <div className="column is-half">
        <div className="card equal-height">
            <div className="card-content is-flex is-horizontal-center">
            <img src="jam-logo.png" width="222" height="222" alt="AWS Deepracer" />            
          </div>
          <div className="card-content">
              <div className="content">
              <h3 className='is-3'>Put your skills to the test!</h3>
              <p><strong>AWS JAM</strong> is an event where participants experience a wide range of AWS services in a series of prepared scenarios that represent common use-cases and operational tasks, such as remediation at scale, automation, forensics, incident response, compliance and many others. <strong>The event is gamified, with teams competing to score points by completing a series of challenges over the course of the event.</strong> 
              </p>
              <p>AWS provides access to pre-created infrastructure that is based on common use cases that participants use to determine what happened, how to best respond, and deploy appropriate solutions. The challenges have varying degrees of difficulty and points associated with them. A live leader-board provides updates on stats and progress. Clues and guidance help your participants move through the challenges. Let's see who is on the top of leaderboard!</p>
              <p>At AWS TIPS 2022, we will be running AWS Jam throughout the event! Would you like to play to learn? Would you like to play to win?</p>
              <p className='has-text-weight-bold'>Register now for AWS TIPS 2022, and <a href="https://jam.awsevents.com/" target="_blank" rel="noreferrer">create your AWS JAM account</a> ready to compete!</p>
              <p className='is-italic has-text-weight-bold'>Please Note: To join our exclusive AWS JAM event, please  <a href="https://app.chime.aws/rooms/e258028e5d501ddac5184e4e1061b41ede24244f7aa8f31dfd6651ebdaca557a" target="_blank" rel="noreferrer">Request access here</a></p>
              </div><br />


                </div>
                </div>
          
          </div>
      </div>
      </section>

    </section>
  );
}
