import React, { Component, Fragment } from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css'
import { DateTime } from 'luxon';
// import ICalendarLink from "react-icalendar-link"
import AgendaData from './sessiondata/agenda.json';
import Keynotes from './sessiondata/agenda-keynotes.json';


export default class Agenda extends Component {


  componentDidMount = () => {

  }  

  getMeeting = (sessionIdRequested, type, geo) => {
    if (type === "keynote" && geo === "amer") { 
      let selectedSession = Keynotes.sessions.find( ({ sessionId }) => sessionId === sessionIdRequested)
      let calevent = {
        title: selectedSession.sessionTitle,
        description: "AWS TIPS 2022 Session: " + selectedSession.sessionTitle + "     Session Outline:    " + selectedSession.sessionAbstract,
        startTime: selectedSession.sessionStartAMER,
        endTime: DateTime.fromISO(selectedSession.sessionStartAMER).plus({ hours: 1 }).toUTC().setZone('UTC-5').toString(),
        location: selectedSession.sessionWebexLinkAMER,
      }
      return calevent

    } else if (type === "keynote" && geo === "emea") {
      let selectedSession = Keynotes.sessions.find( ({ sessionId }) => sessionId === sessionIdRequested)
      let calevent = {
        title: selectedSession.sessionTitle,
        description: "AWS TIPS 2022 Session: " + selectedSession.sessionTitle + "     Session Outline:    " + selectedSession.sessionAbstract,
        startTime: selectedSession.sessionStartEMEA,
        endTime: DateTime.fromISO(selectedSession.sessionStartEMEA).plus({ hours: 1 }).toUTC().setZone('UTC+1').toString(),
        location: selectedSession.sessionWebexLinkEMEA,
      }
      return calevent
    } else if (type === "keynote" && geo === "apjc") {
      let selectedSession = Keynotes.sessions.find( ({ sessionId }) => sessionId === sessionIdRequested)
      let calevent = {
        title: selectedSession.sessionTitle,
        description: "AWS TIPS 2022 Session: " + selectedSession.sessionTitle + "     Session Outline:    " + selectedSession.sessionAbstract,
        startTime: selectedSession.sessionStartAPJC,
        endTime: DateTime.fromISO(selectedSession.sessionStartAPJC).plus({ hours: 1 }).toUTC().setZone('UTC+8').toString(),
        location: selectedSession.sessionWebexLinkAPJC,
      }
      return calevent
    } else if (type === "breakout" && geo === "amer") {
      let selectedSession = AgendaData.sessions.find( ({ sessionId }) => sessionId === sessionIdRequested)
      let calevent = {
        title: selectedSession.sessionTitle,
        description: "AWS TIPS 2022 Session: " + selectedSession.sessionTitle + "     Session Outline:    " + selectedSession.sessionAbstract,
        startTime: selectedSession.sessionStartAMER,
        endTime: DateTime.fromISO(selectedSession.sessionStartAMER).plus({ hours: 1 }).toUTC().setZone('UTC-5').toString(),
        location: selectedSession.sessionWebexLinkAMER,
      }
      return calevent
    } else if (type === "breakout" && geo === "emea") {
      let selectedSession = AgendaData.sessions.find( ({ sessionId }) => sessionId === sessionIdRequested)
      let calevent = {
        title: selectedSession.sessionTitle,
        description: "AWS TIPS 2022 Session: " + selectedSession.sessionTitle + "     Session Outline:    " + selectedSession.sessionAbstract,
        startTime: selectedSession.sessionStartEMEA,
        endTime: DateTime.fromISO(selectedSession.sessionStartEMEA).plus({ hours: 1 }).toUTC().setZone('UTC+1').toString(),
        location: selectedSession.sessionWebexLinkEMEA,
      }
      return calevent
    } else if (type === "breakout" && geo === "apjc") {
      let selectedSession = AgendaData.sessions.find( ({ sessionId }) => sessionId === sessionIdRequested)
      let calevent = {
        title: selectedSession.sessionTitle,
        description: "AWS TIPS 2022 Session: " + selectedSession.sessionTitle + "     Session Outline:    " + selectedSession.sessionAbstract,
        startTime: selectedSession.sessionStartAPJC,
        endTime: DateTime.fromISO(selectedSession.sessionStartAPJC).plus({ hours: 1 }).toUTC().setZone('UTC+8').toString(),
        location: selectedSession.sessionWebexLinkAPJC,
      }
      return calevent
    }
    
    else {
      return "ERROR"
    }
  
  }

  render() {
    return (
      <Fragment>
        <section className="section">
          <div className="container">
          <img src="tips-logo.png" width="400" height="108" alt="aws logo" />
          <br /><br />

          <article className="message is-link is-light">
          <div className="message-body is-size-6">
          <p className="is-size-5"><strong>Tips, for TIPS!:</strong></p> 
          For detailed session information, navigate to your closest Geo at the bottom of this page, or in the menu. You may attend sessions from any Geo, so please feel free to attend sessions that are convenient for you.<br /><br />
          <strong>Joining Sessions:</strong> Session links will be active 30 minutes from session start time. Please use your AWS TIPS registration email to access the Webex sessions. Upon login, Webex will take you to a waiting room until the session begins. <br /><br />
          <p><strong>All sessions are exclusive for AAI members. Session content and materials are confidential and cannot be shared or distributed.</strong></p><br />
          <strong>Below is a high level agenda.</strong>
          </div>
          </article>


            <Tabs>
          <TabList>
            <Tab><strong>Day 1 Overview</strong></Tab>
            <Tab><strong>Day 2 Overview</strong></Tab>
            <Tab><strong>Day 3 Overview</strong></Tab>
            <Tab><strong>Timezone Helper</strong></Tab>
          </TabList>

          <TabPanel>
            <br />
            <h4 className="title is-4">High Level Agenda Overview - Day 1</h4>
            <article className="message is-link is-light">
              <div className="message-body is-size-6">
                <p className="is-size-6 is-italic"><strong>Timezone Note:</strong> The Session details pages contain session times converted to your local system time. The times in the table below are based on the following time zones for their respective GEO:
                  AMER: UTC-5 (Central)  |  EMEA: UTC+1 (London)  |  APJC: UTC+8 (Beijing). For example, "Welcome to AWS TIPS 2022" will commence at 8:00am UTC-5, 8:00am UTC+1 & 8:00am UTC+8.
                  If you would like to see the converted time that a session starts for you based on your local system time, you can also try the "Timezone Helper" tab.</p>
              </div>
            </article>

            <table className="table is-fullwidth is-hoverable is-bordered">
              <thead>
                <tr>
                  <th>Time</th>
                  <th>Duration</th>
                  <th><abbr title="Topic">Topic</abbr></th>
                  <th colSpan="4"><abbr title="Session 1">Title</abbr></th>
                </tr>
              </thead>
              <tfoot>
                <tr>
                  <th>Time</th>
                  <th><abbr title="Duration">Duration</abbr></th>
                  <th><abbr title="Topic">Topic</abbr></th>
                  <th colSpan="4"><abbr title="Session 1">Title</abbr></th>
                </tr>
              </tfoot>
              <tbody>
              <tr className="is selected has-background-danger-light has-text-weight-bold">
                  <th>08:00</th>
                  <td>30 min</td>
                  <td>Welcome</td>
                  <td colSpan="4"><strong>Welcome to AWS TIPS 2022!</strong></td>
                  <td>      
                    <div className="buttons is-centered">
                    <a href={this.getMeeting(100, "keynote", "apjc").location} target="_blank" rel="noreferrer">
                    <button className="button is-danger is-dark is-small is-responsive">
                    <span><strong>Join APJC {DateTime.fromISO(this.getMeeting(100, "keynote", "apjc").startTime).hour.toLocaleString("en-US", {minimumIntegerDigits:2})}:{DateTime.fromISO(this.getMeeting(100, "keynote", "amer").startTime).minute.toLocaleString("en-US", {minimumIntegerDigits:2})} / Apr {DateTime.fromISO(this.getMeeting(100, "keynote", "amer").startTime).day}</strong></span>
                    </button>
                    </a> 
                    <a href={this.getMeeting(100, "keynote", "emea").location} target="_blank" rel="noreferrer">
                    <button className="button is-primary is-dark is-small is-responsive">
                    <span><strong>Join EMEA {DateTime.fromISO(this.getMeeting(100, "keynote", "emea").startTime).hour.toLocaleString("en-US", {minimumIntegerDigits:2})}:{DateTime.fromISO(this.getMeeting(100, "keynote", "amer").startTime).minute.toLocaleString("en-US", {minimumIntegerDigits:2})} / Apr {DateTime.fromISO(this.getMeeting(100, "keynote", "amer").startTime).day}</strong></span>
                    </button>
                    </a>             
                    <a href={this.getMeeting(100, "keynote", "amer").location} target="_blank" rel="noreferrer">
                    <button className="button is-link is-dark is-small is-responsive">
                    <span><strong>Join AMER {DateTime.fromISO(this.getMeeting(100, "keynote", "amer").startTime).hour.toLocaleString("en-US", {minimumIntegerDigits:2})}:{DateTime.fromISO(this.getMeeting(100, "keynote", "amer").startTime).minute.toLocaleString("en-US", {minimumIntegerDigits:2})} / Apr {DateTime.fromISO(this.getMeeting(100, "keynote", "amer").startTime).day}</strong></span>
                    </button>
                    </a>
                    </div>
                  </td>
                </tr>
                <tr className="is selected has-background-danger-light has-text-weight-bold">
                  <th>08:30</th>
                  <td>1 hour</td>
                  <td>Keynote</td>
                  <td colSpan="4">AWS TIPS Keynote: AWS Cloud Strategy</td>
                  <td>      
                    <div className="buttons is-centered">  
                    <a href={this.getMeeting(101, "keynote", "apjc").location} target="_blank" rel="noreferrer">
                    <button className="button is-danger is-dark is-small is-responsive">
                    <span><strong>Join APJC {DateTime.fromISO(this.getMeeting(101, "keynote", "apjc").startTime).hour.toLocaleString("en-US", {minimumIntegerDigits:2})}:{DateTime.fromISO(this.getMeeting(101, "keynote", "amer").startTime).minute.toLocaleString("en-US", {minimumIntegerDigits:2})} / Apr {DateTime.fromISO(this.getMeeting(101, "keynote", "amer").startTime).day}</strong></span>
                    </button>
                    </a>   
                    <a href={this.getMeeting(101, "keynote", "emea").location} target="_blank" rel="noreferrer">
                    <button className="button is-primary is-dark is-small is-responsive">
                    <span><strong>Join EMEA {DateTime.fromISO(this.getMeeting(101, "keynote", "emea").startTime).hour.toLocaleString("en-US", {minimumIntegerDigits:2})}:{DateTime.fromISO(this.getMeeting(101, "keynote", "amer").startTime).minute.toLocaleString("en-US", {minimumIntegerDigits:2})} / Apr {DateTime.fromISO(this.getMeeting(101, "keynote", "amer").startTime).day}</strong></span>
                    </button>
                    </a>         
                    <a href={this.getMeeting(101, "keynote", "amer").location} target="_blank" rel="noreferrer">
                    <button className="button is-link is-dark is-small is-responsive">
                    <span><strong>Join AMER {DateTime.fromISO(this.getMeeting(101, "keynote", "amer").startTime).hour.toLocaleString("en-US", {minimumIntegerDigits:2})}:{DateTime.fromISO(this.getMeeting(101, "keynote", "amer").startTime).minute.toLocaleString("en-US", {minimumIntegerDigits:2})} / Apr {DateTime.fromISO(this.getMeeting(101, "keynote", "amer").startTime).day}</strong></span>
                    </button>
                    </a>
                    </div>
                  </td>
                </tr>
                <tr className="is selected has-background-danger-light has-text-weight-bold">
                  <th>09:30</th>
                  <td>1 hour</td>
                  <td>Keynote</td>
                  <td colSpan="4">AWS TIPS Keynote: The Future of Training with AWS (T&C Strategy & Product Roadmap)</td>
                  <td>      
                    <div className="buttons is-centered">  
                    <a href={this.getMeeting(102, "keynote", "apjc").location} target="_blank" rel="noreferrer">
                    <button className="button is-danger is-dark is-small is-responsive">
                    <span><strong>Join APJC {DateTime.fromISO(this.getMeeting(102, "keynote", "apjc").startTime).hour.toLocaleString("en-US", {minimumIntegerDigits:2})}:{DateTime.fromISO(this.getMeeting(102, "keynote", "amer").startTime).minute.toLocaleString("en-US", {minimumIntegerDigits:2})} / Apr {DateTime.fromISO(this.getMeeting(102, "keynote", "amer").startTime).day}</strong></span>
                    </button>
                    </a>  
                    <a href={this.getMeeting(102, "keynote", "emea").location} target="_blank" rel="noreferrer">
                    <button className="button is-primary is-dark is-small is-responsive">
                    <span><strong>Join EMEA {DateTime.fromISO(this.getMeeting(102, "keynote", "emea").startTime).hour.toLocaleString("en-US", {minimumIntegerDigits:2})}:{DateTime.fromISO(this.getMeeting(102, "keynote", "amer").startTime).minute.toLocaleString("en-US", {minimumIntegerDigits:2})} / Apr {DateTime.fromISO(this.getMeeting(102, "keynote", "amer").startTime).day}</strong></span>
                    </button>
                    </a>          
                    <a href={this.getMeeting(102, "keynote", "amer").location} target="_blank" rel="noreferrer">
                    <button className="button is-link is-dark is-small is-responsive">
                    <span><strong>Join AMER {DateTime.fromISO(this.getMeeting(102, "keynote", "amer").startTime).hour.toLocaleString("en-US", {minimumIntegerDigits:2})}:{DateTime.fromISO(this.getMeeting(102, "keynote", "amer").startTime).minute.toLocaleString("en-US", {minimumIntegerDigits:2})} / Apr {DateTime.fromISO(this.getMeeting(102, "keynote", "amer").startTime).day}</strong></span>
                    </button>
                    </a>
                    </div>
                  </td>
                </tr>
                <tr className="is-selected">
                  <th>10:30</th>
                  <td>15 min</td>
                  <td></td>
                  <td></td>
                  <td><strong>BREAK</strong></td>
                  <td></td>
                  <td></td>
                  <td></td>
                </tr>
                <tr className="is-selected has-background-link-dark has-text-weight-medium">
                  <th>10:45</th>
                  <td>Full Day</td>
                  <td>AWS Jam</td>
                  <td colSpan="5">AWS JAM will be running throughout the 3 Days. Are you playing to win?</td>
                </tr>
                <tr className="is-selected has-background-black has-text-weight-medium">
                  <th>10:45</th>
                  <td>Full Day</td>
                  <td>AWS Deepracer</td>
                  <td colSpan="5">AWS Deepracer event will be running throughout the 3 Days. Developers, start your engines!</td>
                </tr>
                <tr>
                  <th>10:45</th>
                  <td>1 hour</td>                  
                  <td>AAI Breakout Session</td>
                  <td>Delivery Launch Pad: Advanced Well-Architected Best Practices<br />
                  <a className="has-background-danger-dark has-text-white" href={this.getMeeting(106, "breakout", "apjc").location} target="_blank" rel="noreferrer">Join APJC</a><br />
                  <a className="has-background-primary-dark has-text-white" href={this.getMeeting(106, "breakout", "emea").location} target="_blank" rel="noreferrer">Join EMEA</a><br />
                  <a className="has-background-link-dark has-text-white" href={this.getMeeting(106, "breakout", "amer").location} target="_blank" rel="noreferrer">Join AMER</a>
                  </td>
                  <td>Delivery Amplifier: Building Data Lakes on AWS<br />
                  <a className="has-background-danger-dark has-text-white" href={this.getMeeting(107, "breakout", "apjc").location} target="_blank" rel="noreferrer">Join APJC</a><br />
                  <a className="has-background-primary-dark has-text-white" href={this.getMeeting(107, "breakout", "emea").location} target="_blank" rel="noreferrer">Join EMEA</a><br />
                  <a className="has-background-link-dark has-text-white" href={this.getMeeting(107, "breakout", "amer").location} target="_blank" rel="noreferrer">Join AMER</a>
                  </td>
                  <td>Tech Session: What’s new with AWS Storage<br />
                  <a className="has-background-danger-dark has-text-white" href={this.getMeeting(108, "breakout", "apjc").location} target="_blank" rel="noreferrer">Join APJC</a><br />
                  <a className="has-background-primary-dark has-text-white" href={this.getMeeting(108, "breakout", "emea").location} target="_blank" rel="noreferrer">Join EMEA</a><br />
                  <a className="has-background-link-dark has-text-white" href={this.getMeeting(108, "breakout", "amer").location} target="_blank" rel="noreferrer">Join AMER</a>
                  </td>
                  <td>Delivery skills: Accessibility in the classroom<br />
                  <a className="has-background-danger-dark has-text-white" href={this.getMeeting(109, "breakout", "apjc").location} target="_blank" rel="noreferrer">Join APJC</a><br />
                  <a className="has-background-primary-dark has-text-white" href={this.getMeeting(109, "breakout", "emea").location} target="_blank" rel="noreferrer">Join EMEA</a><br />
                  <a className="has-background-link-dark has-text-white" href={this.getMeeting(109, "breakout", "amer").location} target="_blank" rel="noreferrer">Join AMER</a>
                  </td>
                  <td>AAI Program Overview: Programs and resources to support instructors<br />
                  <a className="has-background-danger-dark has-text-white" href={this.getMeeting(110, "breakout", "apjc").location} target="_blank" rel="noreferrer">Join APJC</a><br />
                  <a className="has-background-primary-dark has-text-white" href={this.getMeeting(110, "breakout", "emea").location} target="_blank" rel="noreferrer">Join EMEA</a><br />
                  <a className="has-background-link-dark has-text-white" href={this.getMeeting(110, "breakout", "amer").location} target="_blank" rel="noreferrer">Join AMER</a>
                  </td>
                </tr>
                <tr>
                  <th>11:45</th>
                  <td>1 hour</td>
                  <td>AAI Breakout Session</td>
                  <td>Delivery Launch Pad: Advanced Well-Architected Best Practices (cont)</td>
                  <td>Delivery Amplifier: Migrating to AWS<br />
                  <a className="has-background-danger-dark has-text-white" href={this.getMeeting(111, "breakout", "apjc").location} target="_blank" rel="noreferrer">Join APJC</a><br />
                  <a className="has-background-primary-dark has-text-white" href={this.getMeeting(111, "breakout", "emea").location} target="_blank" rel="noreferrer">Join EMEA</a><br />
                  <a className="has-background-link-dark has-text-white" href={this.getMeeting(111, "breakout", "amer").location} target="_blank" rel="noreferrer">Join AMER</a>
                  </td>
                  <td>Tech session: Welcome to SageMaker Canvas<br />
                  <a className="has-background-danger-dark has-text-white" href={this.getMeeting(112, "breakout", "apjc").location} target="_blank" rel="noreferrer">Join APJC</a><br />
                  <a className="has-background-primary-dark has-text-white" href={this.getMeeting(112, "breakout", "emea").location} target="_blank" rel="noreferrer">Join EMEA</a><br />
                  <a className="has-background-link-dark has-text-white" href={this.getMeeting(112, "breakout", "amer").location} target="_blank" rel="noreferrer">Join AMER</a>
                  </td>
                  <td>Delivery Skills: The Key Building Blocks to Constructing an Effective Delivery<br />
                  <a className="has-background-danger-dark has-text-white" href={this.getMeeting(113, "breakout", "apjc").location} target="_blank" rel="noreferrer">Join APJC</a><br />
                  <a className="has-background-primary-dark has-text-white" href={this.getMeeting(113, "breakout", "emea").location} target="_blank" rel="noreferrer">Join EMEA</a><br />
                  <a className="has-background-link-dark has-text-white" href={this.getMeeting(113, "breakout", "amer").location} target="_blank" rel="noreferrer">Join AMER</a>
                  </td>
                  <td>TM/TDM session: What training managers need to know about the ICA<br />
                  <a className="has-background-danger-dark has-text-white" href={this.getMeeting(114, "breakout", "apjc").location} target="_blank" rel="noreferrer">Join APJC</a><br />
                  <a className="has-background-primary-dark has-text-white" href={this.getMeeting(114, "breakout", "emea").location} target="_blank" rel="noreferrer">Join EMEA</a><br />
                  <a className="has-background-link-dark has-text-white" href={this.getMeeting(114, "breakout", "amer").location} target="_blank" rel="noreferrer">Join AMER</a>
                  </td>
                </tr>
                <tr className="is-selected">
                  <th>12:45</th>
                  <td>45 min</td>
                  <td></td>
                  <td></td>
                  <td><strong>LUNCH</strong></td>
                  <td></td>
                  <td></td>
                  <td></td>
                </tr>
                <tr>
                  <th>13:30</th>
                  <td>1 hour</td>
                  <td>AAI Breakout Session</td>
                  <td>Delivery Launch Pad: MLOps on AWS<br />
                  <a className="has-background-danger-dark has-text-white" href={this.getMeeting(115, "breakout", "apjc").location} target="_blank" rel="noreferrer">Join APJC</a><br />
                  <a className="has-background-primary-dark has-text-white" href={this.getMeeting(115, "breakout", "emea").location} target="_blank" rel="noreferrer">Join EMEA</a><br />
                  <a className="has-background-link-dark has-text-white" href={this.getMeeting(115, "breakout", "amer").location} target="_blank" rel="noreferrer">Join AMER</a>
                  </td>
                  <td>Delivery Amplifier: Architecting on AWS<br />
                  <a className="has-background-danger-dark has-text-white" href={this.getMeeting(116, "breakout", "apjc").location} target="_blank" rel="noreferrer">Join APJC</a><br />
                  <a className="has-background-primary-dark has-text-white" href={this.getMeeting(116, "breakout", "emea").location} target="_blank" rel="noreferrer">Join EMEA</a><br />
                  <a className="has-background-link-dark has-text-white" href={this.getMeeting(116, "breakout", "amer").location} target="_blank" rel="noreferrer">Join AMER</a>
                  </td>
                  <td>Tech session: AWS Glue Studio<br />
                  <a className="has-background-danger-dark has-text-white" href={this.getMeeting(117, "breakout", "apjc").location} target="_blank" rel="noreferrer">Join APJC</a><br />
                  <a className="has-background-primary-dark has-text-white" href={this.getMeeting(117, "breakout", "emea").location} target="_blank" rel="noreferrer">Join EMEA</a><br />
                  <a className="has-background-link-dark has-text-white" href={this.getMeeting(117, "breakout", "amer").location} target="_blank" rel="noreferrer">Join AMER</a>
                  </td>
                  <td>Delivery skills: Crack 2 coconuts with one hit - build a single demo solution for a variety of courses<br />
                  <a className="has-background-danger-dark has-text-white" href={this.getMeeting(118, "breakout", "apjc").location} target="_blank" rel="noreferrer">Join APJC</a><br />
                  <a className="has-background-primary-dark has-text-white" href={this.getMeeting(118, "breakout", "emea").location} target="_blank" rel="noreferrer">Join EMEA</a><br />
                  <a className="has-background-link-dark has-text-white" href={this.getMeeting(118, "breakout", "amer").location} target="_blank" rel="noreferrer">Join AMER</a>
                  </td>
                  <td>Tech session: Strategies to migrate and manage Oracle databases to Amazon EC2, Amazon RDS, and Amazon Aurora<br />
                  <a className="has-background-danger-dark has-text-white" href={this.getMeeting(119, "breakout", "apjc").location} target="_blank" rel="noreferrer">Join APJC</a><br />
                  <a className="has-background-primary-dark has-text-white" href={this.getMeeting(119, "breakout", "emea").location} target="_blank" rel="noreferrer">Join EMEA</a><br />
                  <a className="has-background-link-dark has-text-white" href={this.getMeeting(119, "breakout", "amer").location} target="_blank" rel="noreferrer">Join AMER</a>
                  </td>
                </tr>
                <tr>
                  <th>14:30</th>
                  <td>1 hour</td>
                  <td>AAI Breakout Session</td>
                  <td>Delivery Launch Pad: MLOps on AWS (cont)</td>
                  <td>TM/TDM session: Help Your Trainers Ramp Like Champs!<br />
                  <a className="has-background-danger-dark has-text-white" href={this.getMeeting(120, "breakout", "apjc").location} target="_blank" rel="noreferrer">Join APJC</a><br />
                  <a className="has-background-primary-dark has-text-white" href={this.getMeeting(120, "breakout", "emea").location} target="_blank" rel="noreferrer">Join EMEA</a><br />
                  <a className="has-background-link-dark has-text-white" href={this.getMeeting(120, "breakout", "amer").location} target="_blank" rel="noreferrer">Join AMER</a>
                  </td>
                  <td>Delivery Skills: My boss sent me here - And other 'lively' students<br />
                  <a className="has-background-danger-dark has-text-white" href={this.getMeeting(121, "breakout", "apjc").location} target="_blank" rel="noreferrer">Join APJC</a><br />
                  <a className="has-background-primary-dark has-text-white" href={this.getMeeting(121, "breakout", "emea").location} target="_blank" rel="noreferrer">Join EMEA</a><br />
                  <a className="has-background-link-dark has-text-white" href={this.getMeeting(121, "breakout", "amer").location} target="_blank" rel="noreferrer">Join AMER</a>
                  </td>
                  <td>Tech session: Getting to know VMware Cloud on AWS<br />
                  <a className="has-background-danger-dark has-text-white" href={this.getMeeting(122, "breakout", "apjc").location} target="_blank" rel="noreferrer">Join APJC</a><br />
                  <a className="has-background-primary-dark has-text-white" href={this.getMeeting(122, "breakout", "emea").location} target="_blank" rel="noreferrer">Join EMEA</a><br />
                  <a className="has-background-link-dark has-text-white" href={this.getMeeting(122, "breakout", "amer").location} target="_blank" rel="noreferrer">Join AMER</a>
                  </td>
                  <td></td>
                </tr>
                <tr className="is-selected">
                  <th>15:30</th>
                  <td>15 min</td>
                  <td></td>
                  <td></td>
                  <td><strong>BREAK</strong></td>
                  <td></td>
                  <td></td>
                  <td></td>
                </tr>
                <tr>
                  <th>15:45</th>
                  <td>1 hour</td>
                  <td>AAI Breakout Session</td>
                  <td></td>
                  <td>TM/TDM: Members only but no jacket required - networking in T&C for TMs and TDMs (APJC & AMER Sessions Only)<br />
                  <a className="has-background-danger-dark has-text-white" href={this.getMeeting(123, "breakout", "apjc").location} target="_blank" rel="noreferrer">Join APJC</a><br />
                  <a className="has-background-link-dark has-text-white" href={this.getMeeting(123, "breakout", "amer").location} target="_blank" rel="noreferrer">Join AMER</a>
                  </td>
                  <td>Best practices, common pitfalls, and emerging trends in Machine Learning on AWS<br />
                  <a className="has-background-danger-dark has-text-white" href={this.getMeeting(124, "breakout", "apjc").location} target="_blank" rel="noreferrer">Join APJC</a><br />
                  <a className="has-background-primary-dark has-text-white" href={this.getMeeting(124, "breakout", "emea").location} target="_blank" rel="noreferrer">Join EMEA</a><br />
                  <a className="has-background-link-dark has-text-white" href={this.getMeeting(124, "breakout", "amer").location} target="_blank" rel="noreferrer">Join AMER</a>
                  </td>
                  <td>Delivery skills: Leveraging Mind Maps as a Delivery Technique to Enhance Student Learning<br />
                  <a className="has-background-danger-dark has-text-white" href={this.getMeeting(125, "breakout", "apjc").location} target="_blank" rel="noreferrer">Join APJC</a><br />
                  <a className="has-background-primary-dark has-text-white" href={this.getMeeting(125, "breakout", "emea").location} target="_blank" rel="noreferrer">Join EMEA</a><br />
                  <a className="has-background-link-dark has-text-white" href={this.getMeeting(125, "breakout", "amer").location} target="_blank" rel="noreferrer">Join AMER</a>
                  </td>
                  <td>Demo Showcase<br />
                  <a className="has-background-danger-dark has-text-white" href={this.getMeeting(126, "breakout", "apjc").location} target="_blank" rel="noreferrer">Join APJC</a><br />
                  <a className="has-background-primary-dark has-text-white" href={this.getMeeting(126, "breakout", "emea").location} target="_blank" rel="noreferrer">Join EMEA</a><br />
                  <a className="has-background-link-dark has-text-white" href={this.getMeeting(126, "breakout", "amer").location} target="_blank" rel="noreferrer">Join AMER</a>
                  </td>
                </tr>
                <tr className="is-selected">
                  <th>16:45</th>
                  <td>End</td>
                  <td></td>
                  <td></td>
                  <td><strong>END OF DAY ONE</strong></td>
                  <td></td>
                  <td></td>
                  <td></td>
                </tr>
              </tbody>
            </table>

            </TabPanel>



            <TabPanel>
            <br />
            <h4 className="title is-4">High Level Agenda Overview - Day 2</h4>

            <article className="message is-link is-light">
              <div className="message-body is-size-6">
                <p className="is-size-6 is-italic"><strong>Timezone Note:</strong> The Session details pages contain session times converted to your local system time. The times in the table below are based on the following time zones for their respective GEO:
                  AMER: UTC-5 (Central)  |  EMEA: UTC+1 (London)  |  APJC: UTC+8 (Beijing). For example, "Welcome to AWS TIPS 2022" will commence at 8:00am UTC-5, 8:00am UTC+1 & 8:00am UTC+8.
                  If you would like to see the converted time that a session starts for you based on your local system time, you can also try the "Timezone Helper" tab.</p>
              </div>
            </article>

            <table className="table is-fullwidth is-hoverable is-bordered">
            <thead>
                <tr>
                  <th>Time</th>
                  <th>Duration</th>
                  <th><abbr title="Topic">Topic</abbr></th>
                  <th colSpan="4"><abbr title="Session 1">Title</abbr></th>
                </tr>
              </thead>
              <tfoot>
                <tr>
                  <th>Time</th>
                  <th><abbr title="Duration">Duration</abbr></th>
                  <th><abbr title="Topic">Topic</abbr></th>
                  <th colSpan="4"><abbr title="Session 1">Title</abbr></th>
                </tr>
              </tfoot>
              <tbody>
              <tr className="is selected has-background-danger-light has-text-weight-bold">
                  <th>08:00</th>
                  <td>15 min</td>
                  <td>Welcome</td>
                  <td colSpan="4">Welcome! TIPS Kickoff Day 2!</td>
                  <td>      
                    <div className="buttons is-centered"> 
                    <a href={this.getMeeting(103, "keynote", "apjc").location} target="_blank" rel="noreferrer">
                    <button className="button is-danger is-dark is-small is-responsive">
                    <span><strong>Join APJC {DateTime.fromISO(this.getMeeting(103, "keynote", "apjc").startTime).hour.toLocaleString("en-US", {minimumIntegerDigits:2})}:{DateTime.fromISO(this.getMeeting(103, "keynote", "amer").startTime).minute.toLocaleString("en-US", {minimumIntegerDigits:2})} / Apr {DateTime.fromISO(this.getMeeting(103, "keynote", "amer").startTime).day}</strong></span>
                    </button>
                    </a> 
                    <a href={this.getMeeting(103, "keynote", "emea").location} target="_blank" rel="noreferrer">
                    <button className="button is-primary is-dark is-small is-responsive">
                    <span><strong>Join EMEA {DateTime.fromISO(this.getMeeting(103, "keynote", "emea").startTime).hour.toLocaleString("en-US", {minimumIntegerDigits:2})}:{DateTime.fromISO(this.getMeeting(103, "keynote", "amer").startTime).minute.toLocaleString("en-US", {minimumIntegerDigits:2})} / Apr {DateTime.fromISO(this.getMeeting(103, "keynote", "amer").startTime).day}</strong></span>
                    </button>
                    </a>            
                    <a href={this.getMeeting(103, "keynote", "amer").location} target="_blank" rel="noreferrer">
                    <button className="button is-link is-dark is-small is-responsive">
                    <span><strong>Join AMER {DateTime.fromISO(this.getMeeting(103, "keynote", "amer").startTime).hour.toLocaleString("en-US", {minimumIntegerDigits:2})}:{DateTime.fromISO(this.getMeeting(103, "keynote", "amer").startTime).minute.toLocaleString("en-US", {minimumIntegerDigits:2})} / Apr {DateTime.fromISO(this.getMeeting(103, "keynote", "amer").startTime).day}</strong></span>
                    </button>
                    </a>
                    </div>
                  </td>
                </tr>
                <tr className="is selected has-background-danger-light has-text-weight-bold">
                  <th>08:15</th>
                  <td>1 hour</td>
                  <td>Keynote</td>
                  <td colSpan="4">AWS Training & Certification - Keynote</td>
                  <td>      
                    <div className="buttons is-centered">  
                    <a href={this.getMeeting(104, "keynote", "apjc").location} target="_blank" rel="noreferrer">
                    <button className="button is-danger is-dark is-small is-responsive">
                    <span><strong>Join APJC {DateTime.fromISO(this.getMeeting(104, "keynote", "apjc").startTime).hour.toLocaleString("en-US", {minimumIntegerDigits:2})}:{DateTime.fromISO(this.getMeeting(104, "keynote", "amer").startTime).minute.toLocaleString("en-US", {minimumIntegerDigits:2})} / Apr {DateTime.fromISO(this.getMeeting(104, "keynote", "amer").startTime).day}</strong></span>
                    </button>
                    </a>  
                    <a href={this.getMeeting(104, "keynote", "emea").location} target="_blank" rel="noreferrer">
                    <button className="button is-primary is-dark is-small is-responsive">
                    <span><strong>Join EMEA {DateTime.fromISO(this.getMeeting(104, "keynote", "emea").startTime).hour.toLocaleString("en-US", {minimumIntegerDigits:2})}:{DateTime.fromISO(this.getMeeting(104, "keynote", "amer").startTime).minute.toLocaleString("en-US", {minimumIntegerDigits:2})} / Apr {DateTime.fromISO(this.getMeeting(104, "keynote", "amer").startTime).day}</strong></span>
                    </button>
                    </a>          
                    <a href={this.getMeeting(104, "keynote", "amer").location} target="_blank" rel="noreferrer">
                    <button className="button is-link is-dark is-small is-responsive">
                    <span><strong>Join AMER {DateTime.fromISO(this.getMeeting(104, "keynote", "amer").startTime).hour.toLocaleString("en-US", {minimumIntegerDigits:2})}:{DateTime.fromISO(this.getMeeting(104, "keynote", "amer").startTime).minute.toLocaleString("en-US", {minimumIntegerDigits:2})} / Apr {DateTime.fromISO(this.getMeeting(104, "keynote", "amer").startTime).day}</strong></span>
                    </button>
                    </a>
                    </div>
                  </td>
                </tr>
                <tr className="is selected has-background-danger-light has-text-weight-bold">
                  <th>09:15</th>
                  <td>1 hour</td>
                  <td>Keynote</td>
                  <td colSpan="4">The AWS Training & Certification Product Roadmap</td>
                  <td>      
                    <div className="buttons is-centered">    
                    <a href={this.getMeeting(105, "keynote", "apjc").location} target="_blank" rel="noreferrer">
                    <button className="button is-danger is-dark is-small is-responsive">
                    <span><strong>Join APJC {DateTime.fromISO(this.getMeeting(105, "keynote", "apjc").startTime).hour.toLocaleString("en-US", {minimumIntegerDigits:2})}:{DateTime.fromISO(this.getMeeting(105, "keynote", "amer").startTime).minute.toLocaleString("en-US", {minimumIntegerDigits:2})} / Apr {DateTime.fromISO(this.getMeeting(105, "keynote", "amer").startTime).day}</strong></span>
                    </button>
                    </a>          
                    <a href={this.getMeeting(105, "keynote", "emea").location} target="_blank" rel="noreferrer">
                    <button className="button is-primary is-dark is-small is-responsive">
                    <span><strong>Join EMEA {DateTime.fromISO(this.getMeeting(105, "keynote", "emea").startTime).hour.toLocaleString("en-US", {minimumIntegerDigits:2})}:{DateTime.fromISO(this.getMeeting(105, "keynote", "amer").startTime).minute.toLocaleString("en-US", {minimumIntegerDigits:2})} / Apr {DateTime.fromISO(this.getMeeting(105, "keynote", "amer").startTime).day}</strong></span>
                    </button>
                    </a>
                    <a href={this.getMeeting(105, "keynote", "amer").location} target="_blank" rel="noreferrer">
                    <button className="button is-link is-dark is-small is-responsive">
                    <span><strong>Join AMER {DateTime.fromISO(this.getMeeting(105, "keynote", "amer").startTime).hour.toLocaleString("en-US", {minimumIntegerDigits:2})}:{DateTime.fromISO(this.getMeeting(105, "keynote", "amer").startTime).minute.toLocaleString("en-US", {minimumIntegerDigits:2})} / Apr {DateTime.fromISO(this.getMeeting(105, "keynote", "amer").startTime).day}</strong></span>
                    </button>
                    </a>
                    </div>
                  </td>
                </tr>
                <tr className="is-selected">
                  <th>10:15</th>
                  <td>15 min</td>
                  <td></td>
                  <td></td>
                  <td><strong>BREAK</strong></td>
                  <td></td>
                  <td></td>
                  <td></td>
                </tr>
                <tr className="is-selected has-background-link has-text-weight-medium">
                  <th>10:30</th>
                  <td>Full Day</td>
                  <td>AWS Jam</td>
                  <td colSpan="5">AWS JAM will be running throughout the 3 Days. Are you playing to win?</td>
                </tr>
                <tr className="is-selected has-background-black has-text-weight-medium">
                  <th>10:30</th>
                  <td>Full Day</td>
                  <td>AWS Deepracer</td>
                  <td colSpan="5">AWS Deepracer event will be running throughout the 3 Days. Developers, start your engines!</td>
                </tr>
                <tr>
                  <th>10:30</th>
                  <td>1 hour</td>                  
                  <td>AAI Breakout Session</td>
                  <td>Delivery Launch Pad: Advanced Developing on AWS<br />
                  <a className="has-background-danger-dark has-text-white" href={this.getMeeting(127, "breakout", "apjc").location} target="_blank" rel="noreferrer">Join APJC</a><br />
                  <a className="has-background-primary-dark has-text-white" href={this.getMeeting(127, "breakout", "emea").location} target="_blank" rel="noreferrer">Join EMEA</a><br />
                  <a className="has-background-link-dark has-text-white" href={this.getMeeting(127, "breakout", "amer").location} target="_blank" rel="noreferrer">Join AMER</a>
                  </td>
                  <td>Delivery Amplifier: Machine Learning Pipeline on AWS<br />
                  <a className="has-background-danger-dark has-text-white" href={this.getMeeting(128, "breakout", "apjc").location} target="_blank" rel="noreferrer">Join APJC</a><br />
                  <a className="has-background-primary-dark has-text-white" href={this.getMeeting(128, "breakout", "emea").location} target="_blank" rel="noreferrer">Join EMEA</a><br />
                  <a className="has-background-link-dark has-text-white" href={this.getMeeting(128, "breakout", "amer").location} target="_blank" rel="noreferrer">Join AMER</a>
                  </td>
                  <td>Tech session: Chasing packets using the VPC Reachability Analyzer<br />
                  <a className="ha-background-danger-dark has-text-white" href={this.getMeeting(129, "breakout", "apjc").location} target="_blank" rel="noreferrer">Join APJC</a><br />
                  <a className="has-background-primary-dark has-text-white" href={this.getMeeting(129, "breakout", "emea").location} target="_blank" rel="noreferrer">Join EMEA</a><br />
                  <a className="has-background-link-dark has-text-white" href={this.getMeeting(129, "breakout", "amer").location} target="_blank" rel="noreferrer">Join AMER</a>
                  </td>
                  <td>Delivery skills: Diversity and Inclusion in the classroom
                    <br />
                    <a className="has-background-danger-dark has-text-white" href={this.getMeeting(130, "breakout", "apjc").location} target="_blank" rel="noreferrer">Join APJC</a><br />
                    <a className="has-background-primary-dark has-text-white" href={this.getMeeting(130, "breakout", "emea").location} target="_blank" rel="noreferrer">Join EMEA</a><br />
                    <a className="has-background-link-dark has-text-white" href={this.getMeeting(130, "breakout", "amer").location} target="_blank" rel="noreferrer">Join AMER</a>
                    </td>
                  <td>AAI Candidates: Preparing for ICA
                    <br />
                    <a className="has-background-danger-dark has-text-white" href={this.getMeeting(131, "breakout", "apjc").location} target="_blank" rel="noreferrer">Join APJC</a><br />
                    <a className="has-background-primary-dark has-text-white" href={this.getMeeting(131, "breakout", "emea").location} target="_blank" rel="noreferrer">Join EMEA</a><br />
                    <a className="has-background-link-dark has-text-white" href={this.getMeeting(131, "breakout", "amer").location} target="_blank" rel="noreferrer">Join AMER</a>
                    </td>
                </tr>
                <tr>
                  <th>11:30</th>
                  <td>1 hour</td>
                  <td>AAI Breakout Session</td>
                  <td>Delivery Launch Pad: Advanced Developing on AWS (cont)</td>
                  <td>Delivery Amplifier: Planning and Designing Databases
                    <br />
                    <a className="has-background-danger-dark has-text-white" href={this.getMeeting(132, "breakout", "apjc").location} target="_blank" rel="noreferrer">Join APJC</a><br />
                    <a className="has-background-primary-dark has-text-white" href={this.getMeeting(132, "breakout", "emea").location} target="_blank" rel="noreferrer">Join EMEA</a><br />
                    <a className="has-background-link-dark has-text-white" href={this.getMeeting(132, "breakout", "amer").location} target="_blank" rel="noreferrer">Join AMER</a>
                    </td>
                  <td>Tech session: A little tag here and little tag there
                    <br />
                    <a className="has-background-danger-dark has-text-white" href={this.getMeeting(133, "breakout", "apjc").location} target="_blank" rel="noreferrer">Join APJC</a><br />
                    <a className="has-background-primary-dark has-text-white" href={this.getMeeting(133, "breakout", "emea").location} target="_blank" rel="noreferrer">Join EMEA</a><br />
                    <a className="has-background-link-dark has-text-white" href={this.getMeeting(133, "breakout", "amer").location} target="_blank" rel="noreferrer">Join AMER</a>
                    </td>
                  <td>Delivery Strategies For Decreasing Cognitive Load among Your Students
                    <br />
                    <a className="has-background-danger-dark has-text-white" href={this.getMeeting(134, "breakout", "apjc").location} target="_blank" rel="noreferrer">Join APJC</a><br />
                    <a className="has-background-primary-dark has-text-white" href={this.getMeeting(134, "breakout", "emea").location} target="_blank" rel="noreferrer">Join EMEA</a><br />
                    <a className="has-background-link-dark has-text-white" href={this.getMeeting(134, "breakout", "amer").location} target="_blank" rel="noreferrer">Join AMER</a>
                    </td>
                  <td>Tech session - What's new with AWS Compute?
                    <br />
                    <a className="has-background-danger-dark has-text-white" href={this.getMeeting(135, "breakout", "apjc").location} target="_blank" rel="noreferrer">Join APJC</a><br />
                    <a className="has-background-primary-dark has-text-white" href={this.getMeeting(135, "breakout", "emea").location} target="_blank" rel="noreferrer">Join EMEA</a><br />
                    <a className="has-background-link-dark has-text-white" href={this.getMeeting(135, "breakout", "amer").location} target="_blank" rel="noreferrer">Join AMER</a>
                    </td>
                </tr>
                <tr className="is-selected">
                  <th>12:30</th>
                  <td>45 min</td>
                  <td></td>
                  <td></td>
                  <td><strong>LUNCH</strong></td>
                  <td></td>
                  <td></td>
                  <td></td>
                </tr>
                <tr>
                  <th>13:15</th>
                  <td>1 hour</td>
                  <td>AAI Breakout Session</td>
                  <td>Delivery Launch Pad: Advanced Architecting on AWS
                    <br />
                    <a className="has-background-danger-dark has-text-white" href={this.getMeeting(136, "breakout", "apjc").location} target="_blank" rel="noreferrer">Join APJC</a><br />
                    <a className="has-background-primary-dark has-text-white" href={this.getMeeting(136, "breakout", "emea").location} target="_blank" rel="noreferrer">Join EMEA</a><br />
                    <a className="has-background-link-dark has-text-white" href={this.getMeeting(136, "breakout", "amer").location} target="_blank" rel="noreferrer">Join AMER</a>
                    </td>
                  <td>Delivery Amplifier: Security Engineering on AWS
                    <br />
                    <a className="has-background-danger-dark has-text-white" href={this.getMeeting(137, "breakout", "apjc").location} target="_blank" rel="noreferrer">Join APJC</a><br />
                    <a className="has-background-primary-dark has-text-white" href={this.getMeeting(137, "breakout", "emea").location} target="_blank" rel="noreferrer">Join EMEA</a><br />
                    <a className="has-background-link-dark has-text-white" href={this.getMeeting(137, "breakout", "amer").location} target="_blank" rel="noreferrer">Join AMER</a>
                    </td>
                  <td>Tech session: It's Dangerous to go alone! Take this Serverless Solutions Demo
                    <br />
                    <a className="has-background-danger-dark has-text-white" href={this.getMeeting(138, "breakout", "apjc").location} target="_blank" rel="noreferrer">Join APJC</a><br />
                    <a className="has-background-primary-dark has-text-white" href={this.getMeeting(138, "breakout", "emea").location} target="_blank" rel="noreferrer">Join EMEA</a><br />
                    <a className="has-background-link-dark has-text-white" href={this.getMeeting(138, "breakout", "amer").location} target="_blank" rel="noreferrer">Join AMER</a>
                    </td>
                  <td>Delivery Skills: Improving your Stagecraft or 'Let's Eat Grandma'. (Except APJC - which is Day 3 @ 10:30am)
                    <br />
                    <a className="has-background-primary-dark has-text-white" href={this.getMeeting(139, "breakout", "emea").location} target="_blank" rel="noreferrer">Join EMEA</a><br />
                    <a className="has-background-link-dark has-text-white" href={this.getMeeting(139, "breakout", "amer").location} target="_blank" rel="noreferrer">Join AMER</a>
                    </td>
                  <td>N/A</td>
                </tr>
                <tr>
                  <th>14:15</th>
                  <td>1 hour</td>
                  <td>AAI Breakout Session</td>
                  <td>Delivery Launch Pad: Advanced Architecting on AWS (cont)</td>
                  <td>TM/TDM session: Global Roundtable (APJC & AMER Sessions Only))
                    <br />
                    <a className="has-background-danger-dark has-text-white" href={this.getMeeting(140, "breakout", "apjc").location} target="_blank" rel="noreferrer">Join APJC</a><br />
                    <a className="has-background-link-dark has-text-white" href={this.getMeeting(140, "breakout", "amer").location} target="_blank" rel="noreferrer">Join AMER</a>
                    </td>
                  <td>Tech session: Driving Engagement in Machine Learning through AWS DeepRacer
                    <br />
                    <a className="has-background-danger-dark has-text-white" href={this.getMeeting(141, "breakout", "apjc").location} target="_blank" rel="noreferrer">Join APJC</a><br />
                    <a className="has-background-primary-dark has-text-white" href={this.getMeeting(141, "breakout", "emea").location} target="_blank" rel="noreferrer">Join EMEA</a><br />
                    <a className="has-background-link-dark has-text-white" href={this.getMeeting(141, "breakout", "amer").location} target="_blank" rel="noreferrer">Join AMER</a>
                    </td>
                  <td>Delivery skills: Creating and Maintaining Classroom Engagement
                    <br />
                    <a className="has-background-danger-dark has-text-white" href={this.getMeeting(142, "breakout", "apjc").location} target="_blank" rel="noreferrer">Join APJC</a><br />
                    <a className="has-background-primary-dark has-text-white" href={this.getMeeting(142, "breakout", "emea").location} target="_blank" rel="noreferrer">Join EMEA</a><br />
                    <a className="has-background-link-dark has-text-white" href={this.getMeeting(142, "breakout", "amer").location} target="_blank" rel="noreferrer">Join AMER</a>
                    </td>
                  <td>Technical Session: Amazon API Gateway through a security lens
                    <br />
                    <a className="has-background-danger-dark has-text-white" href={this.getMeeting(143, "breakout", "apjc").location} target="_blank" rel="noreferrer">Join APJC</a><br />
                    <a className="has-background-primary-dark has-text-white" href={this.getMeeting(143, "breakout", "emea").location} target="_blank" rel="noreferrer">Join EMEA</a><br />
                    <a className="has-background-link-dark has-text-white" href={this.getMeeting(143, "breakout", "amer").location} target="_blank" rel="noreferrer">Join AMER</a>
                    </td>
                </tr>
                <tr className="is-selected">
                  <th>15:15</th>
                  <td>15 min</td>
                  <td></td>
                  <td></td>
                  <td><strong>BREAK</strong></td>
                  <td></td>
                  <td></td>
                  <td></td>
                </tr>
                <tr>
                  <th>15:30</th>
                  <td>1 hour</td>
                  <td>AAI Breakout Session</td>
                  <td>Other (Voice of Customer, Trivia, etc.)</td>
                  <td>TM/TDM Session: Gizmo's Tool Box: A Review of Important Tools
                    <br />
                    <a className="has-background-danger-dark has-text-white" href={this.getMeeting(144, "breakout", "apjc").location} target="_blank" rel="noreferrer">Join APJC</a><br />
                    <a className="has-background-primary-dark has-text-white" href={this.getMeeting(144, "breakout", "emea").location} target="_blank" rel="noreferrer">Join EMEA</a><br />
                    <a className="has-background-link-dark has-text-white" href={this.getMeeting(144, "breakout", "amer").location} target="_blank" rel="noreferrer">Join AMER</a>
                    </td>
                  <td>Tech session - SME: Advanced Arch/Networking
                    <br />
                    <a className="has-background-danger-dark has-text-white" href={this.getMeeting(145, "breakout", "apjc").location} target="_blank" rel="noreferrer">Join APJC</a><br />
                    <a className="has-background-primary-dark has-text-white" href={this.getMeeting(145, "breakout", "emea").location} target="_blank" rel="noreferrer">Join EMEA</a><br />
                    <a className="has-background-link-dark has-text-white" href={this.getMeeting(145, "breakout", "amer").location} target="_blank" rel="noreferrer">Join AMER</a>
                    </td>
                  <td>Delivery skills: Strategies for Preventing Instructor Burnout
                    <br />
                    <a className="has-background-danger-dark has-text-white" href={this.getMeeting(146, "breakout", "apjc").location} target="_blank" rel="noreferrer">Join APJC</a><br />
                    <a className="has-background-primary-dark has-text-white" href={this.getMeeting(146, "breakout", "emea").location} target="_blank" rel="noreferrer">Join EMEA</a><br />
                    <a className="has-background-link-dark has-text-white" href={this.getMeeting(146, "breakout", "amer").location} target="_blank" rel="noreferrer">Join AMER</a>
                    </td>
                  <td>Funny stories from past deliveries
                    <br />
                    <a className="has-background-danger-dark has-text-white" href={this.getMeeting(147, "breakout", "apjc").location} target="_blank" rel="noreferrer">Join APJC</a><br />
                    <a className="has-background-primary-dark has-text-white" href={this.getMeeting(147, "breakout", "emea").location} target="_blank" rel="noreferrer">Join EMEA</a><br />
                    <a className="has-background-link-dark has-text-white" href={this.getMeeting(147, "breakout", "amer").location} target="_blank" rel="noreferrer">Join AMER</a>
                    </td>
                </tr>
                <tr className="is-selected">
                  <th>16:30</th>
                  <td>End</td>
                  <td></td>
                  <td></td>
                  <td><strong>END OF DAY TWO</strong></td>
                  <td></td>
                  <td></td>
                  <td></td>
                </tr>
              </tbody>
            </table>

            </TabPanel>


            <TabPanel>
            <br />
            <h4 className="title is-4">High Level Agenda Overview - Day 3</h4>

            <article className="message is-link is-light">
              <div className="message-body is-size-6">
                <p className="is-size-6 is-italic"><strong>Timezone Note:</strong> The Session details pages contain session times converted to your local system time. The times in the table below are based on the following time zones for their respective GEO:
                  AMER: UTC-5 (Central)  |  EMEA: UTC+1 (London)  |  APJC: UTC+8 (Beijing). For example, "Welcome to AWS TIPS 2022" will commence at 8:00am UTC-5, 8:00am UTC+1 & 8:00am UTC+8.
                  If you would like to see the converted time that a session starts for you based on your local system time, you can also try the "Timezone Helper" tab.</p>
              </div>
            </article>

            <table className="table is-fullwidth is-hoverable is-bordered">
            <thead>
                <tr>
                  <th>Time</th>
                  <th>Duration</th>
                  <th><abbr title="Topic">Topic</abbr></th>
                  <th colSpan="4"><abbr title="Session 1">Title</abbr></th>
                </tr>
              </thead>
              <tfoot>
                <tr>
                  <th>Time</th>
                  <th><abbr title="Duration">Duration</abbr></th>
                  <th><abbr title="Topic">Topic</abbr></th>
                  <th colSpan="4"><abbr title="Session 1">Title</abbr></th>
                </tr>
              </tfoot>
              <tbody>
              {/* <tr className="is selected has-background-danger-light has-text-weight-bold">
                  <th>08:00</th>
                  <td>15 min</td>
                  <td>Welcome! TIPS Kickoff Day 3!</td>
                  <td>N/A</td>
                  <td>N/A</td>
                  <td>N/A</td>
                  <td>N/A</td>
                  <td>N/A</td>
                </tr> */}
                <tr>
                  <th>08:15</th>
                  <td>1 hour</td>
                  <td>AAI Breakout Session</td>
                  <td>Delivery Launch Pad: Running Containers on Amazon EKS
                    <br />
                    <a className="has-background-danger-dark has-text-white" href={this.getMeeting(148, "breakout", "apjc").location} target="_blank" rel="noreferrer">Join APJC</a><br />
                    <a className="has-background-primary-dark has-text-white" href={this.getMeeting(148, "breakout", "emea").location} target="_blank" rel="noreferrer">Join EMEA</a><br />
                    <a className="has-background-link-dark has-text-white" href={this.getMeeting(148, "breakout", "amer").location} target="_blank" rel="noreferrer">Join AMER</a>
                    </td>
                  <td>What's New: Developing on AWS
                    <br />
                    <a className="has-background-danger-dark has-text-white" href={this.getMeeting(149, "breakout", "apjc").location} target="_blank" rel="noreferrer">Join APJC</a><br />
                    <a className="has-background-primary-dark has-text-white" href={this.getMeeting(149, "breakout", "emea").location} target="_blank" rel="noreferrer">Join EMEA</a><br />
                    <a className="has-background-link-dark has-text-white" href={this.getMeeting(149, "breakout", "amer").location} target="_blank" rel="noreferrer">Join AMER</a>
                    </td>
                  <td>Tech session: Learning Machine Learning when you're just learning Machine Learning
                    <br />
                    <a className="has-background-danger-dark has-text-white" href={this.getMeeting(150, "breakout", "apjc").location} target="_blank" rel="noreferrer">Join APJC</a><br />
                    <a className="has-background-primary-dark has-text-white" href={this.getMeeting(150, "breakout", "emea").location} target="_blank" rel="noreferrer">Join EMEA</a><br />
                    <a className="has-background-link-dark has-text-white" href={this.getMeeting(150, "breakout", "amer").location} target="_blank" rel="noreferrer">Join AMER</a>
                    </td>
                    <td>Global Delivery Readiness (GDR) Team Meet and Greet
                      <br />
                      <a className="has-background-danger-dark has-text-white" href={this.getMeeting(151, "breakout", "apjc").location} target="_blank" rel="noreferrer">Join APJC</a><br />
                      <a className="has-background-primary-dark has-text-white" href={this.getMeeting(151, "breakout", "emea").location} target="_blank" rel="noreferrer">Join EMEA</a><br />
                      <a className="has-background-link-dark has-text-white" href={this.getMeeting(151, "breakout", "amer").location} target="_blank" rel="noreferrer">Join AMER</a>
                      </td>
                    <td></td>
                </tr>
                <tr className="is-selected">
                  <th>09:15</th>
                  <td>15 min</td>
                  <td></td>
                  <td></td>
                  <td><strong>BREAK</strong></td>
                  <td></td>
                  <td></td>
                  <td></td>
                </tr>
                <tr className="is-selected has-background-link has-text-weight-medium">
                  <th>10:30</th>
                  <td>Full Day</td>
                  <td>AWS Jam</td>
                  <td colspan="5">AWS JAM will be running throughout the 3 Days. Are you playing to win?</td>
                </tr>
                <tr className="is-selected has-background-black has-text-weight-medium">
                  <th>10:30</th>
                  <td>Full Day</td>
                  <td>AWS Deepracer</td>
                  <td colspan="5">AWS Deepracer event will be running throughout the 3 Days. Developers, start your engines!</td>
                </tr>
                <tr>
                  <th>09:30</th>
                  <td>1 hour</td>                  
                  <td>AAI Breakout Session</td>
                  <td>Delivery Launch Pad: Running Containers on Amazon EKS (cont)</td>
                  <td>Delivery Amplifier: Systems Operations on AWS (AMER new time: Day 3 13:30pm)
                    <br />
                    <a className="has-background-primary-dark has-text-white" href={this.getMeeting(152, "breakout", "emea").location} target="_blank" rel="noreferrer">Join EMEA</a><br />
                    <a className="has-background-danger-dark has-text-white" href={this.getMeeting(152, "breakout", "apjc").location} target="_blank" rel="noreferrer">Join APJC</a><br />
                    </td>
                  <td>Tech session: SAP Lens for AWS Well Architected
                    <br />
                    <a className="has-background-danger-dark has-text-white" href={this.getMeeting(153, "breakout", "apjc").location} target="_blank" rel="noreferrer">Join APJC</a><br />
                    <a className="has-background-primary-dark has-text-white" href={this.getMeeting(153, "breakout", "emea").location} target="_blank" rel="noreferrer">Join EMEA</a><br />
                    <a className="has-background-link-dark has-text-white" href={this.getMeeting(153, "breakout", "amer").location} target="_blank" rel="noreferrer">Join AMER</a>
                    </td>
                  <td>Delivery skills: Questioning Techniques: Increasing Engagement and Knowledge Retention
                    <br />
                    <a className="has-background-danger-dark has-text-white" href={this.getMeeting(154, "breakout", "apjc").location} target="_blank" rel="noreferrer">Join APJC</a><br />
                    <a className="has-background-primary-dark has-text-white" href={this.getMeeting(154, "breakout", "emea").location} target="_blank" rel="noreferrer">Join EMEA</a><br />
                    <a className="has-background-link-dark has-text-white" href={this.getMeeting(154, "breakout", "amer").location} target="_blank" rel="noreferrer">Join AMER</a>
                    </td>
                  <td>TM/TDM: Understanding T&C Metrics
                    <br />
                    <a className="has-background-danger-dark has-text-white" href={this.getMeeting(155, "breakout", "apjc").location} target="_blank" rel="noreferrer">Join APJC</a><br />
                    <a className="has-background-primary-dark has-text-white" href={this.getMeeting(155, "breakout", "emea").location} target="_blank" rel="noreferrer">Join EMEA</a><br />
                    <a className="has-background-link-dark has-text-white" href={this.getMeeting(155, "breakout", "amer").location} target="_blank" rel="noreferrer">Join AMER</a>
                    </td>
                </tr>
                <tr>
                  <th>10:30</th>
                  <td>1 hour</td>
                  <td>AAI Breakout Session</td>
                  <td>Voice of the Customer: Cigna's Journey with AWS Training and Certification (AMER Session Only)
                    <br />
                    <a className="has-background-link-dark has-text-white" href={this.getMeeting(156, "breakout", "amer").location} target="_blank" rel="noreferrer">Join AMER</a>
                    </td>
                  <td>Delivery skills: Techniques to Understanding Your Learners During Delivery (EMEA session only; APJC and AMER at 13:30)
                    <br />
                    <a className="has-background-primary-dark has-text-white" href={this.getMeeting(157, "breakout", "emea").location} target="_blank" rel="noreferrer">Join EMEA</a><br />
                    </td>
                  <td>Delivery Skills: Improving your Stagecraft or 'Let's Eat Grandma'. (APJC only - EMEA & AMER are Day 2 - 13:15)
                    <br />
                    <a className="has-background-danger-dark has-text-white" href={this.getMeeting(139, "breakout", "apjc").location} target="_blank" rel="noreferrer">Join APJC</a><br />
                    </td>
                  <td>N/A</td>
                  <td>N/A</td>
                </tr>
                <tr className="is-selected">
                  <th>11:30</th>
                  <td>45 min</td>
                  <td></td>
                  <td></td>
                  <td><strong>LUNCH</strong></td>
                  <td></td>
                  <td></td>
                  <td></td>
                </tr>
                <tr>
                  <th>12:15</th>
                  <td>1 hour</td>
                  <td>AAI Breakout Session</td>
                  <td>Delivery Launch Pad: Building Data Analytics Solutions with Amazon Redshift
                    <br />
                    <a className="has-background-danger-dark has-text-white" href={this.getMeeting(158, "breakout", "apjc").location} target="_blank" rel="noreferrer">Join APJC</a><br />
                    <a className="has-background-primary-dark has-text-white" href={this.getMeeting(158, "breakout", "emea").location} target="_blank" rel="noreferrer">Join EMEA</a><br />
                    <a className="has-background-link-dark has-text-white" href={this.getMeeting(158, "breakout", "amer").location} target="_blank" rel="noreferrer">Join AMER</a>
                    </td>
                  <td>Black Belt Serverless 2022
                    <br />
                    <a className="has-background-danger-dark has-text-white" href={this.getMeeting(159, "breakout", "apjc").location} target="_blank" rel="noreferrer">Join APJC</a><br />
                    <a className="has-background-primary-dark has-text-white" href={this.getMeeting(159, "breakout", "emea").location} target="_blank" rel="noreferrer">Join EMEA</a><br />
                    <a className="has-background-link-dark has-text-white" href={this.getMeeting(159, "breakout", "amer").location} target="_blank" rel="noreferrer">Join AMER</a>
                    </td>
                  <td>Tech session: How to demo the AWS CLI/API using Jupyter Notebook
                    <br />
                    <a className="has-background-danger-dark has-text-white" href={this.getMeeting(160, "breakout", "apjc").location} target="_blank" rel="noreferrer">Join APJC</a><br />
                    <a className="has-background-primary-dark has-text-white" href={this.getMeeting(160, "breakout", "emea").location} target="_blank" rel="noreferrer">Join EMEA</a><br />
                    <a className="has-background-link-dark has-text-white" href={this.getMeeting(160, "breakout", "amer").location} target="_blank" rel="noreferrer">Join AMER</a>
                    </td>
                  <td>Delivery skills: Using Examples, Narratives, and Phrases to Reinforce AWS Best Practices
                    <br />
                    <a className="has-background-danger-dark has-text-white" href={this.getMeeting(161, "breakout", "apjc").location} target="_blank" rel="noreferrer">Join APJC</a><br />
                    <a className="has-background-primary-dark has-text-white" href={this.getMeeting(161, "breakout", "emea").location} target="_blank" rel="noreferrer">Join EMEA</a><br />
                    <a className="has-background-link-dark has-text-white" href={this.getMeeting(161, "breakout", "amer").location} target="_blank" rel="noreferrer">Join AMER</a>
                    </td>
                  <td>N/A</td>
                </tr>
                <tr>
                  <th>12:15 cont</th>
                  <td>1 hour</td>
                  <td>AAI Breakout Session</td>
                  <td>Delivery Skills: Be more effective as a trainer by creating searchable, reusable content
                    <br />
                    <a className="has-background-danger-dark has-text-white" href={this.getMeeting(166, "breakout", "apjc").location} target="_blank" rel="noreferrer">Join APJC</a><br />
                    <a className="has-background-primary-dark has-text-white" href={this.getMeeting(166, "breakout", "emea").location} target="_blank" rel="noreferrer">Join EMEA</a><br />
                    <a className="has-background-link-dark has-text-white" href={this.getMeeting(166, "breakout", "amer").location} target="_blank" rel="noreferrer">Join AMER</a>
                    </td>
                    <td>N/A</td>
                    <td>N/A</td>
                    <td>N/A</td>
                    <td>N/A</td>
                </tr>
                <tr className="is-selected">
                  <th>13:15</th>
                  <td>15 min</td>
                  <td></td>
                  <td></td>
                  <td><strong>BREAK</strong></td>
                  <td></td>
                  <td></td>
                  <td></td>
                </tr>
                <tr>
                  <th>13:30</th>
                  <td>1 hour</td>
                  <td>AAI Breakout Session</td>
                  <td>Delivery Launch Pad: Building Data Analytics Solutions with Amazon Redshift (cont)</td>
                  <td>Delivery Amplifier: Systems Operations on AWS (AMER only time, EMEA & APJC at 09:30)
                    <br />
                    <a className="has-background-link-dark has-text-white" href={this.getMeeting(152, "breakout", "amer").location} target="_blank" rel="noreferrer">Join AMER</a>
                    </td>
                    <td>Delivery skills: Techniques to Understanding Your Learners During Delivery (APJC & AMER session only; EMEA at 10:30)
                    <br />
                    <a className="has-background-danger-dark has-text-white" href={this.getMeeting(157, "breakout", "apjc").location} target="_blank" rel="noreferrer">Join APJC</a><br />
                    <a className="has-background-link-dark has-text-white" href={this.getMeeting(157, "breakout", "amer").location} target="_blank" rel="noreferrer">Join AMER</a>
                    </td>                  
                    <td>Delivery Skills: Doing Amazing Demos
                      <br />
                      <a className="has-background-danger-dark has-text-white" href={this.getMeeting(163, "breakout", "apjc").location} target="_blank" rel="noreferrer">Join APJC</a><br />
                      <a className="has-background-primary-dark has-text-white" href={this.getMeeting(163, "breakout", "emea").location} target="_blank" rel="noreferrer">Join EMEA</a><br />
                      <a className="has-background-link-dark has-text-white" href={this.getMeeting(163, "breakout", "amer").location} target="_blank" rel="noreferrer">Join AMER</a>
                      </td>
                  <td>AAI Trivia
                    <br />
                    <a className="has-background-danger-dark has-text-white" href={this.getMeeting(164, "breakout", "apjc").location} target="_blank" rel="noreferrer">Join APJC</a><br />
                    <a className="has-background-primary-dark has-text-white" href={this.getMeeting(164, "breakout", "emea").location} target="_blank" rel="noreferrer">Join EMEA</a><br />
                    <a className="has-background-link-dark has-text-white" href={this.getMeeting(164, "breakout", "amer").location} target="_blank" rel="noreferrer">Join AMER</a>
                    </td>
                </tr>
                {/* <tr className="is selected has-background-danger-light has-text-weight-bold">
                  <th>14:30</th>
                  <td>15 min</td>
                  <td>Closing Session</td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td>   
                  <div className="buttons is-centered">    
                    <a href={this.getMeeting(165, "keynote", "amer").location} target="_blank" rel="noreferrer">
                    <button className="button is-link is-dark is-small is-responsive">
                    <span><strong>Join AMER {DateTime.fromISO(this.getMeeting(165, "keynote", "amer").startTime).hour.toLocaleString("en-US", {minimumIntegerDigits:2})}:{DateTime.fromISO(this.getMeeting(165, "keynote", "amer").startTime).minute.toLocaleString("en-US", {minimumIntegerDigits:2})} / Apr {DateTime.fromISO(this.getMeeting(165, "keynote", "amer").startTime).day}</strong></span>
                    </button>
                    </a>
                    </div>          
                  </td>
                </tr> */}
                <tr className="is-selected">
                  <th>14:45</th>
                  <td>End</td>
                  <td></td>
                  <td></td>
                  <td><strong>END OF AWS TIPS 2022</strong></td>
                  <td></td>
                  <td></td>
                  <td></td>
                </tr>
              </tbody>
            </table>

            </TabPanel>


            <TabPanel>
            <br />
            <h4 className="title is-5">Timezone Helper - what time will it be for you? <strong>Based on local system: ({DateTime.now().zoneName})</strong></h4>

            <table className="table is-striped is-bordered is-hoverable is-fullwidth">
              <thead>
                <tr>
                  <th>Time</th>
                  <th>AMER Day 1</th>
                  <th>EMEA Day 1</th>
                  <th>APJC Day 1</th>
                  <th>AMER Day 2</th>
                  <th>EMEA Day 2</th>
                  <th>APJC Day 2</th>
                  <th>AMER Day 3</th>
                  <th>EMEA Day 3</th>
                  <th>APJC Day 3</th>
                </tr>
              </thead>
              <tfoot>
                <tr>
                  <th>Time</th>
                  <th>AMER Day 1</th>
                  <th>EMEA Day 1</th>
                  <th>APJC Day 1</th>
                  <th>AMER Day 2</th>
                  <th>EMEA Day 2</th>
                  <th>APJC Day 2</th>
                  <th>AMER Day 3</th>
                  <th>EMEA Day 3</th>
                  <th>APJC Day 3</th>
                </tr>
              </tfoot>
              <tbody>
                <tr>
                  <th>08:00</th>
                  <td>{DateTime.fromISO("2022-04-05T08:00:00.000-05:00").hour.toLocaleString("en-US", {minimumIntegerDigits:2})}:{DateTime.fromISO("2022-04-05T08:00:00.000-05:00").minute.toLocaleString("en-US", {minimumIntegerDigits:2})} / April {DateTime.fromISO("2022-04-05T08:00:00.000-05:00").day}</td>
                  <td>{DateTime.fromISO("2022-04-05T08:00:00.000+01:00").hour.toLocaleString("en-US", {minimumIntegerDigits:2})}:{DateTime.fromISO("2022-04-05T08:00:00.000+01:00").minute.toLocaleString("en-US", {minimumIntegerDigits:2})} / April {DateTime.fromISO("2022-04-05T08:00:00.000+01:00").day}</td>
                  <td>{DateTime.fromISO("2022-04-05T08:00:00.000+08:00").hour.toLocaleString("en-US", {minimumIntegerDigits:2})}:{DateTime.fromISO("2022-04-05T08:00:00.000+08:00").minute.toLocaleString("en-US", {minimumIntegerDigits:2})} / April {DateTime.fromISO("2022-04-05T08:00:00.000+08:00").day}</td>
                  <td>{DateTime.fromISO("2022-04-06T08:00:00.000-05:00").hour.toLocaleString("en-US", {minimumIntegerDigits:2})}:{DateTime.fromISO("2022-04-06T08:00:00.000-05:00").minute.toLocaleString("en-US", {minimumIntegerDigits:2})} / April {DateTime.fromISO("2022-04-06T08:00:00.000-05:00").day}</td>
                  <td>{DateTime.fromISO("2022-04-06T08:00:00.000+01:00").hour.toLocaleString("en-US", {minimumIntegerDigits:2})}:{DateTime.fromISO("2022-04-06T08:00:00.000+01:00").minute.toLocaleString("en-US", {minimumIntegerDigits:2})} / April {DateTime.fromISO("2022-04-06T08:00:00.000+01:00").day}</td>
                  <td>{DateTime.fromISO("2022-04-06T08:00:00.000+08:00").hour.toLocaleString("en-US", {minimumIntegerDigits:2})}:{DateTime.fromISO("2022-04-06T08:00:00.000+08:00").minute.toLocaleString("en-US", {minimumIntegerDigits:2})} / April {DateTime.fromISO("2022-04-06T08:00:00.000+08:00").day}</td>
                  <td>{DateTime.fromISO("2022-04-07T08:00:00.000-05:00").hour.toLocaleString("en-US", {minimumIntegerDigits:2})}:{DateTime.fromISO("2022-04-07T08:00:00.000-05:00").minute.toLocaleString("en-US", {minimumIntegerDigits:2})} / April {DateTime.fromISO("2022-04-07T08:00:00.000-05:00").day}</td>
                  <td>{DateTime.fromISO("2022-04-07T08:00:00.000+01:00").hour.toLocaleString("en-US", {minimumIntegerDigits:2})}:{DateTime.fromISO("2022-04-07T08:00:00.000+01:00").minute.toLocaleString("en-US", {minimumIntegerDigits:2})} / April {DateTime.fromISO("2022-04-07T08:00:00.000+01:00").day}</td>
                  <td>{DateTime.fromISO("2022-04-07T08:00:00.000+08:00").hour.toLocaleString("en-US", {minimumIntegerDigits:2})}:{DateTime.fromISO("2022-04-07T08:00:00.000+08:00").minute.toLocaleString("en-US", {minimumIntegerDigits:2})} / April {DateTime.fromISO("2022-04-07T08:00:00.000+08:00").day}</td>
                </tr>
                <tr>
                <th>09:00</th>
                  <td>{DateTime.fromISO("2022-04-05T09:00:00.000-05:00").hour.toLocaleString("en-US", {minimumIntegerDigits:2})}:{DateTime.fromISO("2022-04-05T09:00:00.000-05:00").minute.toLocaleString("en-US", {minimumIntegerDigits:2})} / April {DateTime.fromISO("2022-04-05T09:00:00.000-05:00").day}</td>
                  <td>{DateTime.fromISO("2022-04-05T09:00:00.000+01:00").hour.toLocaleString("en-US", {minimumIntegerDigits:2})}:{DateTime.fromISO("2022-04-05T09:00:00.000+01:00").minute.toLocaleString("en-US", {minimumIntegerDigits:2})} / April {DateTime.fromISO("2022-04-05T09:00:00.000+01:00").day}</td>
                  <td>{DateTime.fromISO("2022-04-05T09:00:00.000+08:00").hour.toLocaleString("en-US", {minimumIntegerDigits:2})}:{DateTime.fromISO("2022-04-05T09:00:00.000+08:00").minute.toLocaleString("en-US", {minimumIntegerDigits:2})} / April {DateTime.fromISO("2022-04-05T09:00:00.000+08:00").day}</td>
                  <td>{DateTime.fromISO("2022-04-06T09:00:00.000-05:00").hour.toLocaleString("en-US", {minimumIntegerDigits:2})}:{DateTime.fromISO("2022-04-06T09:00:00.000-05:00").minute.toLocaleString("en-US", {minimumIntegerDigits:2})} / April {DateTime.fromISO("2022-04-06T09:00:00.000-05:00").day}</td>
                  <td>{DateTime.fromISO("2022-04-06T09:00:00.000+01:00").hour.toLocaleString("en-US", {minimumIntegerDigits:2})}:{DateTime.fromISO("2022-04-06T09:00:00.000+01:00").minute.toLocaleString("en-US", {minimumIntegerDigits:2})} / April {DateTime.fromISO("2022-04-06T09:00:00.000+01:00").day}</td>
                  <td>{DateTime.fromISO("2022-04-06T09:00:00.000+08:00").hour.toLocaleString("en-US", {minimumIntegerDigits:2})}:{DateTime.fromISO("2022-04-06T09:00:00.000+08:00").minute.toLocaleString("en-US", {minimumIntegerDigits:2})} / April {DateTime.fromISO("2022-04-06T09:00:00.000+08:00").day}</td>
                  <td>{DateTime.fromISO("2022-04-07T09:00:00.000-05:00").hour.toLocaleString("en-US", {minimumIntegerDigits:2})}:{DateTime.fromISO("2022-04-07T09:00:00.000-05:00").minute.toLocaleString("en-US", {minimumIntegerDigits:2})} / April {DateTime.fromISO("2022-04-07T09:00:00.000-05:00").day}</td>
                  <td>{DateTime.fromISO("2022-04-07T09:00:00.000+01:00").hour.toLocaleString("en-US", {minimumIntegerDigits:2})}:{DateTime.fromISO("2022-04-07T09:00:00.000+01:00").minute.toLocaleString("en-US", {minimumIntegerDigits:2})} / April {DateTime.fromISO("2022-04-07T09:00:00.000+01:00").day}</td>
                  <td>{DateTime.fromISO("2022-04-07T09:00:00.000+08:00").hour.toLocaleString("en-US", {minimumIntegerDigits:2})}:{DateTime.fromISO("2022-04-07T09:00:00.000+08:00").minute.toLocaleString("en-US", {minimumIntegerDigits:2})} / April {DateTime.fromISO("2022-04-07T09:00:00.000+08:00").day}</td>
                </tr>
                <tr>
                <th>10:00</th>
                  <td>{DateTime.fromISO("2022-04-05T10:00:00.000-05:00").hour.toLocaleString("en-US", {minimumIntegerDigits:2})}:{DateTime.fromISO("2022-04-05T10:00:00.000-05:00").minute.toLocaleString("en-US", {minimumIntegerDigits:2})} / April {DateTime.fromISO("2022-04-05T10:00:00.000-05:00").day}</td>
                  <td>{DateTime.fromISO("2022-04-05T10:00:00.000+01:00").hour.toLocaleString("en-US", {minimumIntegerDigits:2})}:{DateTime.fromISO("2022-04-05T10:00:00.000+01:00").minute.toLocaleString("en-US", {minimumIntegerDigits:2})} / April {DateTime.fromISO("2022-04-05T10:00:00.000+01:00").day}</td>
                  <td>{DateTime.fromISO("2022-04-05T10:00:00.000+08:00").hour.toLocaleString("en-US", {minimumIntegerDigits:2})}:{DateTime.fromISO("2022-04-05T10:00:00.000+08:00").minute.toLocaleString("en-US", {minimumIntegerDigits:2})} / April {DateTime.fromISO("2022-04-05T10:00:00.000+08:00").day}</td>
                  <td>{DateTime.fromISO("2022-04-06T10:00:00.000-05:00").hour.toLocaleString("en-US", {minimumIntegerDigits:2})}:{DateTime.fromISO("2022-04-06T10:00:00.000-05:00").minute.toLocaleString("en-US", {minimumIntegerDigits:2})} / April {DateTime.fromISO("2022-04-06T10:00:00.000-05:00").day}</td>
                  <td>{DateTime.fromISO("2022-04-06T10:00:00.000+01:00").hour.toLocaleString("en-US", {minimumIntegerDigits:2})}:{DateTime.fromISO("2022-04-06T10:00:00.000+01:00").minute.toLocaleString("en-US", {minimumIntegerDigits:2})} / April {DateTime.fromISO("2022-04-06T10:00:00.000+01:00").day}</td>
                  <td>{DateTime.fromISO("2022-04-06T10:00:00.000+08:00").hour.toLocaleString("en-US", {minimumIntegerDigits:2})}:{DateTime.fromISO("2022-04-06T10:00:00.000+08:00").minute.toLocaleString("en-US", {minimumIntegerDigits:2})} / April {DateTime.fromISO("2022-04-06T10:00:00.000+08:00").day}</td>
                  <td>{DateTime.fromISO("2022-04-07T10:00:00.000-05:00").hour.toLocaleString("en-US", {minimumIntegerDigits:2})}:{DateTime.fromISO("2022-04-07T10:00:00.000-05:00").minute.toLocaleString("en-US", {minimumIntegerDigits:2})} / April {DateTime.fromISO("2022-04-07T10:00:00.000-05:00").day}</td>
                  <td>{DateTime.fromISO("2022-04-07T10:00:00.000+01:00").hour.toLocaleString("en-US", {minimumIntegerDigits:2})}:{DateTime.fromISO("2022-04-07T10:00:00.000+01:00").minute.toLocaleString("en-US", {minimumIntegerDigits:2})} / April {DateTime.fromISO("2022-04-07T10:00:00.000+01:00").day}</td>
                  <td>{DateTime.fromISO("2022-04-07T10:00:00.000+08:00").hour.toLocaleString("en-US", {minimumIntegerDigits:2})}:{DateTime.fromISO("2022-04-07T10:00:00.000+08:00").minute.toLocaleString("en-US", {minimumIntegerDigits:2})} / April {DateTime.fromISO("2022-04-07T10:00:00.000+08:00").day}</td>
                </tr>
                <tr>
                <th>11:00</th>
                  <td>{DateTime.fromISO("2022-04-05T11:00:00.000-05:00").hour.toLocaleString("en-US", {minimumIntegerDigits:2})}:{DateTime.fromISO("2022-04-05T11:00:00.000-05:00").minute.toLocaleString("en-US", {minimumIntegerDigits:2})} / April {DateTime.fromISO("2022-04-05T11:00:00.000-05:00").day}</td>
                  <td>{DateTime.fromISO("2022-04-05T11:00:00.000+01:00").hour.toLocaleString("en-US", {minimumIntegerDigits:2})}:{DateTime.fromISO("2022-04-05T11:00:00.000+01:00").minute.toLocaleString("en-US", {minimumIntegerDigits:2})} / April {DateTime.fromISO("2022-04-05T11:00:00.000+01:00").day}</td>
                  <td>{DateTime.fromISO("2022-04-05T11:00:00.000+08:00").hour.toLocaleString("en-US", {minimumIntegerDigits:2})}:{DateTime.fromISO("2022-04-05T11:00:00.000+08:00").minute.toLocaleString("en-US", {minimumIntegerDigits:2})} / April {DateTime.fromISO("2022-04-05T11:00:00.000+08:00").day}</td>
                  <td>{DateTime.fromISO("2022-04-06T11:00:00.000-05:00").hour.toLocaleString("en-US", {minimumIntegerDigits:2})}:{DateTime.fromISO("2022-04-06T11:00:00.000-05:00").minute.toLocaleString("en-US", {minimumIntegerDigits:2})} / April {DateTime.fromISO("2022-04-06T11:00:00.000-05:00").day}</td>
                  <td>{DateTime.fromISO("2022-04-06T11:00:00.000+01:00").hour.toLocaleString("en-US", {minimumIntegerDigits:2})}:{DateTime.fromISO("2022-04-06T11:00:00.000+01:00").minute.toLocaleString("en-US", {minimumIntegerDigits:2})} / April {DateTime.fromISO("2022-04-06T11:00:00.000+01:00").day}</td>
                  <td>{DateTime.fromISO("2022-04-06T11:00:00.000+08:00").hour.toLocaleString("en-US", {minimumIntegerDigits:2})}:{DateTime.fromISO("2022-04-06T11:00:00.000+08:00").minute.toLocaleString("en-US", {minimumIntegerDigits:2})} / April {DateTime.fromISO("2022-04-06T11:00:00.000+08:00").day}</td>
                  <td>{DateTime.fromISO("2022-04-07T11:00:00.000-05:00").hour.toLocaleString("en-US", {minimumIntegerDigits:2})}:{DateTime.fromISO("2022-04-07T11:00:00.000-05:00").minute.toLocaleString("en-US", {minimumIntegerDigits:2})} / April {DateTime.fromISO("2022-04-07T11:00:00.000-05:00").day}</td>
                  <td>{DateTime.fromISO("2022-04-07T11:00:00.000+01:00").hour.toLocaleString("en-US", {minimumIntegerDigits:2})}:{DateTime.fromISO("2022-04-07T11:00:00.000+01:00").minute.toLocaleString("en-US", {minimumIntegerDigits:2})} / April {DateTime.fromISO("2022-04-07T11:00:00.000+01:00").day}</td>
                  <td>{DateTime.fromISO("2022-04-07T11:00:00.000+08:00").hour.toLocaleString("en-US", {minimumIntegerDigits:2})}:{DateTime.fromISO("2022-04-07T11:00:00.000+08:00").minute.toLocaleString("en-US", {minimumIntegerDigits:2})} / April {DateTime.fromISO("2022-04-07T11:00:00.000+08:00").day}</td>
                </tr>
                <tr>
                <th>12:00</th>
                  <td>{DateTime.fromISO("2022-04-05T12:00:00.000-05:00").hour.toLocaleString("en-US", {minimumIntegerDigits:2})}:{DateTime.fromISO("2022-04-05T12:00:00.000-05:00").minute.toLocaleString("en-US", {minimumIntegerDigits:2})} / April {DateTime.fromISO("2022-04-05T12:00:00.000-05:00").day}</td>
                  <td>{DateTime.fromISO("2022-04-05T12:00:00.000+01:00").hour.toLocaleString("en-US", {minimumIntegerDigits:2})}:{DateTime.fromISO("2022-04-05T12:00:00.000+01:00").minute.toLocaleString("en-US", {minimumIntegerDigits:2})} / April {DateTime.fromISO("2022-04-05T12:00:00.000+01:00").day}</td>
                  <td>{DateTime.fromISO("2022-04-05T12:00:00.000+08:00").hour.toLocaleString("en-US", {minimumIntegerDigits:2})}:{DateTime.fromISO("2022-04-05T12:00:00.000+08:00").minute.toLocaleString("en-US", {minimumIntegerDigits:2})} / April {DateTime.fromISO("2022-04-05T12:00:00.000+08:00").day}</td>
                  <td>{DateTime.fromISO("2022-04-06T12:00:00.000-05:00").hour.toLocaleString("en-US", {minimumIntegerDigits:2})}:{DateTime.fromISO("2022-04-06T12:00:00.000-05:00").minute.toLocaleString("en-US", {minimumIntegerDigits:2})} / April {DateTime.fromISO("2022-04-06T12:00:00.000-05:00").day}</td>
                  <td>{DateTime.fromISO("2022-04-06T12:00:00.000+01:00").hour.toLocaleString("en-US", {minimumIntegerDigits:2})}:{DateTime.fromISO("2022-04-06T12:00:00.000+01:00").minute.toLocaleString("en-US", {minimumIntegerDigits:2})} / April {DateTime.fromISO("2022-04-06T12:00:00.000+01:00").day}</td>
                  <td>{DateTime.fromISO("2022-04-06T12:00:00.000+08:00").hour.toLocaleString("en-US", {minimumIntegerDigits:2})}:{DateTime.fromISO("2022-04-06T12:00:00.000+08:00").minute.toLocaleString("en-US", {minimumIntegerDigits:2})} / April {DateTime.fromISO("2022-04-06T12:00:00.000+08:00").day}</td>
                  <td>{DateTime.fromISO("2022-04-07T12:00:00.000-05:00").hour.toLocaleString("en-US", {minimumIntegerDigits:2})}:{DateTime.fromISO("2022-04-07T12:00:00.000-05:00").minute.toLocaleString("en-US", {minimumIntegerDigits:2})} / April {DateTime.fromISO("2022-04-07T12:00:00.000-05:00").day}</td>
                  <td>{DateTime.fromISO("2022-04-07T12:00:00.000+01:00").hour.toLocaleString("en-US", {minimumIntegerDigits:2})}:{DateTime.fromISO("2022-04-07T12:00:00.000+01:00").minute.toLocaleString("en-US", {minimumIntegerDigits:2})} / April {DateTime.fromISO("2022-04-07T12:00:00.000+01:00").day}</td>
                  <td>{DateTime.fromISO("2022-04-07T12:00:00.000+08:00").hour.toLocaleString("en-US", {minimumIntegerDigits:2})}:{DateTime.fromISO("2022-04-07T12:00:00.000+08:00").minute.toLocaleString("en-US", {minimumIntegerDigits:2})} / April {DateTime.fromISO("2022-04-07T12:00:00.000+08:00").day}</td>
                </tr>
                <tr>
                <th>13:00</th>
                  <td>{DateTime.fromISO("2022-04-05T13:00:00.000-05:00").hour.toLocaleString("en-US", {minimumIntegerDigits:2})}:{DateTime.fromISO("2022-04-05T13:00:00.000-05:00").minute.toLocaleString("en-US", {minimumIntegerDigits:2})} / April {DateTime.fromISO("2022-04-05T13:00:00.000-05:00").day}</td>
                  <td>{DateTime.fromISO("2022-04-05T13:00:00.000+01:00").hour.toLocaleString("en-US", {minimumIntegerDigits:2})}:{DateTime.fromISO("2022-04-05T13:00:00.000+01:00").minute.toLocaleString("en-US", {minimumIntegerDigits:2})} / April {DateTime.fromISO("2022-04-05T13:00:00.000+01:00").day}</td>
                  <td>{DateTime.fromISO("2022-04-05T13:00:00.000+08:00").hour.toLocaleString("en-US", {minimumIntegerDigits:2})}:{DateTime.fromISO("2022-04-05T13:00:00.000+08:00").minute.toLocaleString("en-US", {minimumIntegerDigits:2})} / April {DateTime.fromISO("2022-04-05T13:00:00.000+08:00").day}</td>
                  <td>{DateTime.fromISO("2022-04-06T13:00:00.000-05:00").hour.toLocaleString("en-US", {minimumIntegerDigits:2})}:{DateTime.fromISO("2022-04-06T13:00:00.000-05:00").minute.toLocaleString("en-US", {minimumIntegerDigits:2})} / April {DateTime.fromISO("2022-04-06T13:00:00.000-05:00").day}</td>
                  <td>{DateTime.fromISO("2022-04-06T13:00:00.000+01:00").hour.toLocaleString("en-US", {minimumIntegerDigits:2})}:{DateTime.fromISO("2022-04-06T13:00:00.000+01:00").minute.toLocaleString("en-US", {minimumIntegerDigits:2})} / April {DateTime.fromISO("2022-04-06T13:00:00.000+01:00").day}</td>
                  <td>{DateTime.fromISO("2022-04-06T13:00:00.000+08:00").hour.toLocaleString("en-US", {minimumIntegerDigits:2})}:{DateTime.fromISO("2022-04-06T13:00:00.000+08:00").minute.toLocaleString("en-US", {minimumIntegerDigits:2})} / April {DateTime.fromISO("2022-04-06T13:00:00.000+08:00").day}</td>
                  <td>{DateTime.fromISO("2022-04-07T13:00:00.000-05:00").hour.toLocaleString("en-US", {minimumIntegerDigits:2})}:{DateTime.fromISO("2022-04-07T13:00:00.000-05:00").minute.toLocaleString("en-US", {minimumIntegerDigits:2})} / April {DateTime.fromISO("2022-04-07T13:00:00.000-05:00").day}</td>
                  <td>{DateTime.fromISO("2022-04-07T13:00:00.000+01:00").hour.toLocaleString("en-US", {minimumIntegerDigits:2})}:{DateTime.fromISO("2022-04-07T13:00:00.000+01:00").minute.toLocaleString("en-US", {minimumIntegerDigits:2})} / April {DateTime.fromISO("2022-04-07T13:00:00.000+01:00").day}</td>
                  <td>{DateTime.fromISO("2022-04-07T13:00:00.000+08:00").hour.toLocaleString("en-US", {minimumIntegerDigits:2})}:{DateTime.fromISO("2022-04-07T13:00:00.000+08:00").minute.toLocaleString("en-US", {minimumIntegerDigits:2})} / April {DateTime.fromISO("2022-04-07T13:00:00.000+08:00").day}</td>
                </tr>
                <tr>
                <th>14:00</th>
                  <td>{DateTime.fromISO("2022-04-05T14:00:00.000-05:00").hour.toLocaleString("en-US", {minimumIntegerDigits:2})}:{DateTime.fromISO("2022-04-05T14:00:00.000-05:00").minute.toLocaleString("en-US", {minimumIntegerDigits:2})} / April {DateTime.fromISO("2022-04-05T14:00:00.000-05:00").day}</td>
                  <td>{DateTime.fromISO("2022-04-05T14:00:00.000+01:00").hour.toLocaleString("en-US", {minimumIntegerDigits:2})}:{DateTime.fromISO("2022-04-05T14:00:00.000+01:00").minute.toLocaleString("en-US", {minimumIntegerDigits:2})} / April {DateTime.fromISO("2022-04-05T14:00:00.000+01:00").day}</td>
                  <td>{DateTime.fromISO("2022-04-05T14:00:00.000+08:00").hour.toLocaleString("en-US", {minimumIntegerDigits:2})}:{DateTime.fromISO("2022-04-05T14:00:00.000+08:00").minute.toLocaleString("en-US", {minimumIntegerDigits:2})} / April {DateTime.fromISO("2022-04-05T14:00:00.000+08:00").day}</td>
                  <td>{DateTime.fromISO("2022-04-06T14:00:00.000-05:00").hour.toLocaleString("en-US", {minimumIntegerDigits:2})}:{DateTime.fromISO("2022-04-06T14:00:00.000-05:00").minute.toLocaleString("en-US", {minimumIntegerDigits:2})} / April {DateTime.fromISO("2022-04-06T14:00:00.000-05:00").day}</td>
                  <td>{DateTime.fromISO("2022-04-06T14:00:00.000+01:00").hour.toLocaleString("en-US", {minimumIntegerDigits:2})}:{DateTime.fromISO("2022-04-06T14:00:00.000+01:00").minute.toLocaleString("en-US", {minimumIntegerDigits:2})} / April {DateTime.fromISO("2022-04-06T14:00:00.000+01:00").day}</td>
                  <td>{DateTime.fromISO("2022-04-06T14:00:00.000+08:00").hour.toLocaleString("en-US", {minimumIntegerDigits:2})}:{DateTime.fromISO("2022-04-06T14:00:00.000+08:00").minute.toLocaleString("en-US", {minimumIntegerDigits:2})} / April {DateTime.fromISO("2022-04-06T14:00:00.000+08:00").day}</td>
                  <td>{DateTime.fromISO("2022-04-07T14:00:00.000-05:00").hour.toLocaleString("en-US", {minimumIntegerDigits:2})}:{DateTime.fromISO("2022-04-07T14:00:00.000-05:00").minute.toLocaleString("en-US", {minimumIntegerDigits:2})} / April {DateTime.fromISO("2022-04-07T14:00:00.000-05:00").day}</td>
                  <td>{DateTime.fromISO("2022-04-07T14:00:00.000+01:00").hour.toLocaleString("en-US", {minimumIntegerDigits:2})}:{DateTime.fromISO("2022-04-07T14:00:00.000+01:00").minute.toLocaleString("en-US", {minimumIntegerDigits:2})} / April {DateTime.fromISO("2022-04-07T14:00:00.000+01:00").day}</td>
                  <td>{DateTime.fromISO("2022-04-07T14:00:00.000+08:00").hour.toLocaleString("en-US", {minimumIntegerDigits:2})}:{DateTime.fromISO("2022-04-07T14:00:00.000+08:00").minute.toLocaleString("en-US", {minimumIntegerDigits:2})} / April {DateTime.fromISO("2022-04-07T14:00:00.000+08:00").day}</td>
                </tr>
                <tr>
                <th>15:00</th>
                  <td>{DateTime.fromISO("2022-04-05T15:00:00.000-05:00").hour.toLocaleString("en-US", {minimumIntegerDigits:2})}:{DateTime.fromISO("2022-04-05T15:00:00.000-05:00").minute.toLocaleString("en-US", {minimumIntegerDigits:2})} / April {DateTime.fromISO("2022-04-05T15:00:00.000-05:00").day}</td>
                  <td>{DateTime.fromISO("2022-04-05T15:00:00.000+01:00").hour.toLocaleString("en-US", {minimumIntegerDigits:2})}:{DateTime.fromISO("2022-04-05T15:00:00.000+01:00").minute.toLocaleString("en-US", {minimumIntegerDigits:2})} / April {DateTime.fromISO("2022-04-05T15:00:00.000+01:00").day}</td>
                  <td>{DateTime.fromISO("2022-04-05T15:00:00.000+08:00").hour.toLocaleString("en-US", {minimumIntegerDigits:2})}:{DateTime.fromISO("2022-04-05T15:00:00.000+08:00").minute.toLocaleString("en-US", {minimumIntegerDigits:2})} / April {DateTime.fromISO("2022-04-05T15:00:00.000+08:00").day}</td>
                  <td>{DateTime.fromISO("2022-04-06T15:00:00.000-05:00").hour.toLocaleString("en-US", {minimumIntegerDigits:2})}:{DateTime.fromISO("2022-04-06T15:00:00.000-05:00").minute.toLocaleString("en-US", {minimumIntegerDigits:2})} / April {DateTime.fromISO("2022-04-06T15:00:00.000-05:00").day}</td>
                  <td>{DateTime.fromISO("2022-04-06T15:00:00.000+01:00").hour.toLocaleString("en-US", {minimumIntegerDigits:2})}:{DateTime.fromISO("2022-04-06T15:00:00.000+01:00").minute.toLocaleString("en-US", {minimumIntegerDigits:2})} / April {DateTime.fromISO("2022-04-06T15:00:00.000+01:00").day}</td>
                  <td>{DateTime.fromISO("2022-04-06T15:00:00.000+08:00").hour.toLocaleString("en-US", {minimumIntegerDigits:2})}:{DateTime.fromISO("2022-04-06T15:00:00.000+08:00").minute.toLocaleString("en-US", {minimumIntegerDigits:2})} / April {DateTime.fromISO("2022-04-06T15:00:00.000+08:00").day}</td>
                  <td>{DateTime.fromISO("2022-04-07T15:00:00.000-05:00").hour.toLocaleString("en-US", {minimumIntegerDigits:2})}:{DateTime.fromISO("2022-04-07T15:00:00.000-05:00").minute.toLocaleString("en-US", {minimumIntegerDigits:2})} / April {DateTime.fromISO("2022-04-07T15:00:00.000-05:00").day}</td>
                  <td>{DateTime.fromISO("2022-04-07T15:00:00.000+01:00").hour.toLocaleString("en-US", {minimumIntegerDigits:2})}:{DateTime.fromISO("2022-04-07T15:00:00.000+01:00").minute.toLocaleString("en-US", {minimumIntegerDigits:2})} / April {DateTime.fromISO("2022-04-07T15:00:00.000+01:00").day}</td>
                  <td>{DateTime.fromISO("2022-04-07T15:00:00.000+08:00").hour.toLocaleString("en-US", {minimumIntegerDigits:2})}:{DateTime.fromISO("2022-04-07T15:00:00.000+08:00").minute.toLocaleString("en-US", {minimumIntegerDigits:2})} / April {DateTime.fromISO("2022-04-07T15:00:00.000+08:00").day}</td>
                </tr>
                <tr>
                <th>16:00</th>
                  <td>{DateTime.fromISO("2022-04-05T16:00:00.000-05:00").hour.toLocaleString("en-US", {minimumIntegerDigits:2})}:{DateTime.fromISO("2022-04-05T16:00:00.000-05:00").minute.toLocaleString("en-US", {minimumIntegerDigits:2})} / April {DateTime.fromISO("2022-04-05T16:00:00.000-05:00").day}</td>
                  <td>{DateTime.fromISO("2022-04-05T16:00:00.000+01:00").hour.toLocaleString("en-US", {minimumIntegerDigits:2})}:{DateTime.fromISO("2022-04-05T16:00:00.000+01:00").minute.toLocaleString("en-US", {minimumIntegerDigits:2})} / April {DateTime.fromISO("2022-04-05T16:00:00.000+01:00").day}</td>
                  <td>{DateTime.fromISO("2022-04-05T16:00:00.000+08:00").hour.toLocaleString("en-US", {minimumIntegerDigits:2})}:{DateTime.fromISO("2022-04-05T16:00:00.000+08:00").minute.toLocaleString("en-US", {minimumIntegerDigits:2})} / April {DateTime.fromISO("2022-04-05T16:00:00.000+08:00").day}</td>
                  <td>{DateTime.fromISO("2022-04-06T16:00:00.000-05:00").hour.toLocaleString("en-US", {minimumIntegerDigits:2})}:{DateTime.fromISO("2022-04-06T16:00:00.000-05:00").minute.toLocaleString("en-US", {minimumIntegerDigits:2})} / April {DateTime.fromISO("2022-04-06T16:00:00.000-05:00").day}</td>
                  <td>{DateTime.fromISO("2022-04-06T16:00:00.000+01:00").hour.toLocaleString("en-US", {minimumIntegerDigits:2})}:{DateTime.fromISO("2022-04-06T16:00:00.000+01:00").minute.toLocaleString("en-US", {minimumIntegerDigits:2})} / April {DateTime.fromISO("2022-04-06T16:00:00.000+01:00").day}</td>
                  <td>{DateTime.fromISO("2022-04-06T16:00:00.000+08:00").hour.toLocaleString("en-US", {minimumIntegerDigits:2})}:{DateTime.fromISO("2022-04-06T16:00:00.000+08:00").minute.toLocaleString("en-US", {minimumIntegerDigits:2})} / April {DateTime.fromISO("2022-04-06T16:00:00.000+08:00").day}</td>
                  <td>{DateTime.fromISO("2022-04-07T16:00:00.000-05:00").hour.toLocaleString("en-US", {minimumIntegerDigits:2})}:{DateTime.fromISO("2022-04-07T16:00:00.000-05:00").minute.toLocaleString("en-US", {minimumIntegerDigits:2})} / April {DateTime.fromISO("2022-04-07T16:00:00.000-05:00").day}</td>
                  <td>{DateTime.fromISO("2022-04-07T16:00:00.000+01:00").hour.toLocaleString("en-US", {minimumIntegerDigits:2})}:{DateTime.fromISO("2022-04-07T16:00:00.000+01:00").minute.toLocaleString("en-US", {minimumIntegerDigits:2})} / April {DateTime.fromISO("2022-04-07T16:00:00.000+01:00").day}</td>
                  <td>{DateTime.fromISO("2022-04-07T16:00:00.000+08:00").hour.toLocaleString("en-US", {minimumIntegerDigits:2})}:{DateTime.fromISO("2022-04-07T16:00:00.000+08:00").minute.toLocaleString("en-US", {minimumIntegerDigits:2})} / April {DateTime.fromISO("2022-04-07T16:00:00.000+08:00").day}</td>
                </tr>
              </tbody>
            </table>

            </TabPanel>





</Tabs>

        <br /><br /><br />

        <div className="notification is-danger is-dark">
            <a href="/apjc">
              <strong><p className="is-size-5">Asia Pacific, Japan & China Session Details (APJC)</p></strong>
              </a>
            </div>

            <div className="notification is-primary is-dark">
            <a href="/europe">
              <strong><p className="is-size-5">Europe Session Details (EMEA)</p></strong>
              </a>
            </div>

            <div className="notification is-link is-dark">
            <a href="/americas">
              <strong><p className="is-size-5">Americas Session Details (AMER)</p></strong>
              </a>
            </div>

            <div className="notification is-black">
            <a href="/atp">
              <strong><p className="is-size-5">ATP Focused Sessions</p></strong>
              </a>
            </div>



          </div>
        </section>
      </Fragment>
    );
  }

}
