import React, { Component, Fragment } from 'react';
import Agenda from './sessiondata/agenda.json';
import Keynotes from './sessiondata/agenda-keynotes.json';
import { DateTime } from 'luxon';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css'
import ICalendarLink from "react-icalendar-link"


Agenda.sessions.sort(function(a,b) {
  return (DateTime.fromISO(a.sessionStartAMER).toUTC().setZone('UTC-5').hour < DateTime.fromISO(b.sessionStartAMER).toUTC().setZone('UTC-5').hour) ? -1 : ((DateTime.fromISO(a.sessionStartAMER).toUTC().setZone('UTC-5').hour > DateTime.fromISO(b.sessionStartAMER).toUTC().setZone('UTC-5').hour) ? 1 : 0)
});

Keynotes.sessions.sort(function(a,b) {
  return (DateTime.fromISO(a.sessionStartAMER).toUTC().setZone('UTC-5').hour < DateTime.fromISO(b.sessionStartAMER).toUTC().setZone('UTC-5').hour) ? -1 : ((DateTime.fromISO(a.sessionStartAMER).toUTC().setZone('UTC-5').hour > DateTime.fromISO(b.sessionStartAMER).toUTC().setZone('UTC-5').hour) ? 1 : 0)
});


export default class Americas extends Component {


  componentDidMount = () => {

  }  

  getMeeting = (title, type) => {
    if (type === "keynote") { 
      let selectedSession = Keynotes.sessions.find( ({ sessionTitle }) => sessionTitle === title)
      let calevent = {
        title: "TIPS2022 Session: " + selectedSession.sessionTitle,
        description: "AWS TIPS 2022 Session: " + selectedSession.sessionTitle + "     Session Outline:    " + selectedSession.sessionAbstract,
        startTime: selectedSession.sessionStartAMER,
        endTime: DateTime.fromISO(selectedSession.sessionStartAMER).plus({ hours: 1 }).toUTC().setZone('UTC-5').toString(),
        location: "Webex Link: " + selectedSession.sessionWebexLinkAMER,
      }
      return calevent
    } else {
      let selectedSession = Agenda.sessions.find( ({ sessionTitle }) => sessionTitle === title)
      let calevent = {
        title: "TIPS2022 Session: " + selectedSession.sessionTitle,
        description: "AWS TIPS 2022 Session: " + selectedSession.sessionTitle + "     Session Outline:    " + selectedSession.sessionAbstract,
        startTime: selectedSession.sessionStartAMER,
        endTime: DateTime.fromISO(selectedSession.sessionStartAMER).plus({ hours: 1 }).toUTC().setZone('UTC-5').toString(),
        location: "Webex Link: " + selectedSession.sessionWebexLinkAMER,
      }
      return calevent
    }
  
  }

  render() {

    return (
      <Fragment>
        <section className="section">
        <div className="container">

        <Tabs>
          <TabList>
            <Tab><strong>Keynotes & Leadership Sessions</strong></Tab>
            <Tab><strong>Day 1</strong></Tab>
            <Tab><strong>Day 2</strong></Tab>
            <Tab><strong>Day 3</strong></Tab>
          </TabList>

          <TabPanel> 
        <div className="notification is-link">
        <strong><p className="is-size-4">TIPS 2022 - Day 1 AMER Sessions (April 5)</p></strong>
        </div>
          {Keynotes.sessions.map((session) => (
              session.sessionDayAMER === 1 && session.sessionStatusAMER === "enabled" ?
              <div className="notification is-link is-light" key={session.sessionTitle}> 
              <p className="is-size-5"><strong>{session.sessionTitle}</strong></p>
              <br />
              <strong>Duration:</strong> {session.sessionDuration} hours
              <br />
              <strong>Start Time (UTC-5):</strong> {DateTime.fromISO(session.sessionStartAMER).toUTC().setZone('UTC-5').hour.toLocaleString("en-US", {minimumIntegerDigits:2})}:{DateTime.fromISO(session.sessionStartAMER).toUTC().setZone('UTC-5').minute.toLocaleString("en-US", {minimumIntegerDigits:2})} / April {DateTime.fromISO(session.sessionStartAMER).toUTC().setZone('UTC-5').day}
              <br />
              <strong>Local Start Time (24hr) ({DateTime.fromISO(session.sessionStartAMER).zoneName}):</strong> {DateTime.fromISO(session.sessionStartAMER).hour.toLocaleString("en-US", {minimumIntegerDigits:2})}:{DateTime.fromISO(session.sessionStartAMER).minute.toLocaleString("en-US", {minimumIntegerDigits:2})} / April {DateTime.fromISO(session.sessionStartAMER).day}
              <br />
              <ICalendarLink filename={"AWSTIPS2022 - " + session.sessionTitle + ".ics"} event={this.getMeeting(session.sessionTitle, "keynote")} >
                <button className="button is-black is-small is-responsive">
                <span><strong>Add to Calendar</strong></span>
              </button>
              </ICalendarLink>
              <br />
              <strong>Presenter(s):</strong> {session.sessionHostAMER}
              <br />
              <strong>Domain:</strong> {session.sessionDomain}
              <br />
              <strong>Session Type:</strong> {session.sessionType}
              <br />
              <br />
              <strong>Abstract:</strong> {session.sessionAbstract}
              <br />
              <br />
              {DateTime.now().toUTC().setZone('UTC-5') >= DateTime.fromISO(session.sessionStartAMER).toUTC().setZone('UTC-5').minus({ minutes: 30000 }) ?  
              <a href={session.sessionWebexLinkAMER} target="_blank" rel="noreferrer">
              <button className="button is-link">
                <span className="icon is-small">
                  <i className="fas fa-paper-plane"></i>
                </span>
                <span>Join Session</span>
              </button>
              </a>
              :
              <button className="button is-warning is-responsive">
                <span className="icon is-small">
                  <i className="fas fa-paper-plane"></i>
                </span>
                <span>Available 30 minutes before session</span>
              </button>
          }
            </div> 
            : null

          ))}


<div className="notification is-link">
        <strong><p className="is-size-4">TIPS 2022 - Day 2 AMER Sessions (April 6)</p></strong>
        </div>
          {Keynotes.sessions.map((session) => (
              session.sessionDayAMER === 2 && session.sessionStatusAMER === "enabled" ?           
              <div className="notification is-link is-light" key={session.sessionTitle}> 
              <p className="is-size-5"><strong>{session.sessionTitle}</strong></p>
              <br />
              <strong>Duration:</strong> {session.sessionDuration} hours
              <br />
              <strong>Start Time (UTC-5):</strong> {DateTime.fromISO(session.sessionStartAMER).toUTC().setZone('UTC-5').hour.toLocaleString("en-US", {minimumIntegerDigits:2})}:{DateTime.fromISO(session.sessionStartAMER).toUTC().setZone('UTC-5').minute.toLocaleString("en-US", {minimumIntegerDigits:2})} / April {DateTime.fromISO(session.sessionStartAMER).toUTC().setZone('UTC-5').day}
              <br />
              <strong>Local Start Time (24hr) ({DateTime.fromISO(session.sessionStartAMER).zoneName}):</strong> {DateTime.fromISO(session.sessionStartAMER).hour.toLocaleString("en-US", {minimumIntegerDigits:2})}:{DateTime.fromISO(session.sessionStartAMER).minute.toLocaleString("en-US", {minimumIntegerDigits:2})} / April {DateTime.fromISO(session.sessionStartAMER).day}
              <br />
              <ICalendarLink filename={"AWSTIPS2022 - " + session.sessionTitle + ".ics"} event={this.getMeeting(session.sessionTitle, "keynote")} >
                <button className="button is-black is-small is-responsive">
                <span><strong>Add to Calendar</strong></span>
              </button>
              </ICalendarLink>
              <br />
              <strong>Presenter(s):</strong> {session.sessionHostAMER}
              <br />
              <strong>Domain:</strong> {session.sessionDomain}
              <br />
              <strong>Session Type:</strong> {session.sessionType}
              <br />
              <br />
              <strong>Abstract:</strong> {session.sessionAbstract}
              <br />
              <br />
              {DateTime.now().toUTC().setZone('UTC-5') >= DateTime.fromISO(session.sessionStartAMER).toUTC().setZone('UTC-5').minus({ minutes: 30000 }) ?  
              <a href={session.sessionWebexLinkAMER} target="_blank" rel="noreferrer">
              <button className="button is-link">
                <span className="icon is-small">
                  <i className="fas fa-paper-plane"></i>
                </span>
                <span>Join Session</span>
              </button>
              </a>
              :

              <button className="button is-warning">
                <span className="icon is-small">
                  <i className="fas fa-paper-plane"></i>
                </span>
                <span>Available 30 minutes before session</span>
              </button>
          }
            </div> 
            : null
          ))}

        {/* <div className="notification is-link">
        <strong><p className="is-size-4">TIPS 2022 - Day 3 AMER Sessions (April 7)</p></strong>
        </div> */}

          {Keynotes.sessions.map((session) => (
              session.sessionDayAMER === 3 && session.sessionStatusAMER === "enabled" ?           
              <div className="notification is-link is-light" key={session.sessionTitle}> 
              <p className="is-size-5"><strong>{session.sessionTitle}</strong></p>
              <br />
              <strong>Duration:</strong> {session.sessionDuration} hours
              <br />
              <strong>Start Time (UTC-5):</strong> {DateTime.fromISO(session.sessionStartAMER).toUTC().setZone('UTC-5').hour.toLocaleString("en-US", {minimumIntegerDigits:2})}:{DateTime.fromISO(session.sessionStartAMER).toUTC().setZone('UTC-5').minute.toLocaleString("en-US", {minimumIntegerDigits:2})} / April {DateTime.fromISO(session.sessionStartAMER).toUTC().setZone('UTC-5').day}
              <br />
              <strong>Local Start Time (24hr) ({DateTime.fromISO(session.sessionStartAMER).zoneName}):</strong> {DateTime.fromISO(session.sessionStartAMER).hour.toLocaleString("en-US", {minimumIntegerDigits:2})}:{DateTime.fromISO(session.sessionStartAMER).minute.toLocaleString("en-US", {minimumIntegerDigits:2})} / April {DateTime.fromISO(session.sessionStartAMER).day}
              <br />
              <ICalendarLink filename={"AWSTIPS2022 - " + session.sessionTitle + ".ics"} event={this.getMeeting(session.sessionTitle, "keynote")} >
                <button className="button is-black is-small is-responsive">
                <span><strong>Add to Calendar</strong></span>
              </button>
              </ICalendarLink>
              <br />
              <strong>Presenter(s):</strong> {session.sessionHostAMER}
              <br />
              <strong>Domain:</strong> {session.sessionDomain}
              <br />
              <strong>Session Type:</strong> {session.sessionType}
              <br />
              <br />
              <strong>Abstract:</strong> {session.sessionAbstract}
              <br />
              <br />
              {DateTime.now().toUTC().setZone('UTC-5') >= DateTime.fromISO(session.sessionStartAMER).toUTC().setZone('UTC-5').minus({ minutes: 30000 }) ?  
              <a href={session.sessionWebexLinkAMER} target="_blank" rel="noreferrer">
              <button className="button is-link">
                <span className="icon is-small">
                  <i className="fas fa-paper-plane"></i>
                </span>
                <span>Join Session</span>
              </button>
              </a>
              :
              <button className="button is-warning">
                <span className="icon is-small">
                  <i className="fas fa-paper-plane"></i>
                </span>
                <span>Available 30 minutes before session</span>
              </button>
          }
            </div> 
            : null
          ))}
          </TabPanel>

        <TabPanel> 
        <div className="notification is-link">
        <strong><p className="is-size-4">TIPS 2022 - Day 1 AMER Sessions (April 5)</p></strong>
        </div>
          {Agenda.sessions.map((session) => (
              session.sessionDayAMER === 1 && session.sessionStatusAMER === "enabled" ?           
              <div className="notification is-link is-light" key={session.sessionTitle}> 
              <p className="is-size-5"><strong>{session.sessionTitle}</strong></p>
              <br />
              <strong>Duration:</strong> {session.sessionDuration} hours
              <br />
              <strong>Start Time (UTC-5):</strong> {DateTime.fromISO(session.sessionStartAMER).toUTC().setZone('UTC-5').hour.toLocaleString("en-US", {minimumIntegerDigits:2})}:{DateTime.fromISO(session.sessionStartAMER).toUTC().setZone('UTC-5').minute.toLocaleString("en-US", {minimumIntegerDigits:2})} / April {DateTime.fromISO(session.sessionStartAMER).toUTC().setZone('UTC-5').day}
              <br />
              <strong>Local Start Time (24hr) ({DateTime.fromISO(session.sessionStartAMER).zoneName}):</strong> {DateTime.fromISO(session.sessionStartAMER).hour.toLocaleString("en-US", {minimumIntegerDigits:2})}:{DateTime.fromISO(session.sessionStartAMER).minute.toLocaleString("en-US", {minimumIntegerDigits:2})} / April {DateTime.fromISO(session.sessionStartAMER).day}
              <br />
              <ICalendarLink filename={"AWSTIPS2022 - " + session.sessionTitle + ".ics"} event={this.getMeeting(session.sessionTitle, "breakout")} >
                <button className="button is-black is-small is-responsive">
                <span><strong>Add to Calendar</strong></span>
              </button>
              </ICalendarLink>
              <br />
              <strong>Presenter(s):</strong> {session.sessionHostAMER}
              <br />
              <strong>Domain:</strong> {session.sessionDomain}
              <br />
              <strong>Session Type:</strong> {session.sessionType}
              <br />
              <br />
              <strong>Abstract:</strong> {session.sessionAbstract}
              <br />
              <br />
              {DateTime.now().toUTC().setZone('UTC-5') >= DateTime.fromISO(session.sessionStartAMER).toUTC().setZone('UTC-5').minus({ minutes: 30000 }) ?  
              <a href={session.sessionWebexLinkAMER} target="_blank" rel="noreferrer">
              <button className="button is-link">
                <span className="icon is-small">
                  <i className="fas fa-paper-plane"></i>
                </span>
                <span>Join Session</span>
              </button>
              </a>
              :

              <button className="button is-warning">
                <span className="icon is-small">
                  <i className="fas fa-paper-plane"></i>
                </span>
                <span>Available 30 minutes before session</span>
              </button>
          }
            </div> 
            : null
          ))}
          </TabPanel>

          <TabPanel> 
            <div className="notification is-link">
              <strong><p className="is-size-4">TIPS 2022 - Day 2 AMER Sessions (April 6)</p></strong>
            </div>
            {Agenda.sessions.map((session) => (
              session.sessionDayAMER === 2 && session.sessionStatusAMER === "enabled" ?           
              <div className="notification is-link is-light" key={session.sessionTitle}> 
              <p className="is-size-5"><strong>{session.sessionTitle}</strong></p>
              <br />
              <strong>Duration:</strong> {session.sessionDuration} hours
              <br />
              <strong>Start Time (UTC-5):</strong> {DateTime.fromISO(session.sessionStartAMER).toUTC().setZone('UTC-5').hour.toLocaleString("en-US", {minimumIntegerDigits:2})}:{DateTime.fromISO(session.sessionStartAMER).toUTC().setZone('UTC-5').minute.toLocaleString("en-US", {minimumIntegerDigits:2})} / April {DateTime.fromISO(session.sessionStartAMER).toUTC().setZone('UTC-5').day}
              <br />
              <strong>Local Start Time (24hr) ({DateTime.fromISO(session.sessionStartAMER).zoneName}):</strong> {DateTime.fromISO(session.sessionStartAMER).hour.toLocaleString("en-US", {minimumIntegerDigits:2})}:{DateTime.fromISO(session.sessionStartAMER).minute.toLocaleString("en-US", {minimumIntegerDigits:2})} / April {DateTime.fromISO(session.sessionStartAMER).day}
              <br />
              <ICalendarLink filename={"AWSTIPS2022 - " + session.sessionTitle + ".ics"} event={this.getMeeting(session.sessionTitle, "breakout")} >
                <button className="button is-black is-small is-responsive">
                <span><strong>Add to Calendar</strong></span>
              </button>
              </ICalendarLink>
              <br />
              <strong>Presenter(s):</strong> {session.sessionHostAMER}
              <br />
              <strong>Domain:</strong> {session.sessionDomain}
              <br />
              <strong>Session Type:</strong> {session.sessionType}
              <br />
              <br />
              <strong>Abstract:</strong> {session.sessionAbstract}
              <br />
              <br />
              {DateTime.now().toUTC().setZone('UTC-5') >= DateTime.fromISO(session.sessionStartAMER).toUTC().setZone('UTC-5').minus({ minutes: 30000 }) ?  
              <a href={session.sessionWebexLinkAMER} target="_blank" rel="noreferrer">
              <button className="button is-link">
                <span className="icon is-small">
                  <i className="fas fa-paper-plane"></i>
                </span>
                <span>Join Session</span>
              </button>
              </a>
              :
              <button className="button is-warning">
                <span className="icon is-small">
                  <i className="fas fa-paper-plane"></i>
                </span>
                <span>Available 30 minutes before session</span>
              </button>
          }
            </div> 
            : null
          ))}
          </TabPanel> 
          <TabPanel>  
            <div className="notification is-link">
              <strong><p className="is-size-4">TIPS 2022 - Day 3 AMER Sessions (April 7)</p></strong>
            </div>
            {Agenda.sessions.map((session) => (
              session.sessionDayAMER === 3 && session.sessionStatusAMER === "enabled" ?           
              <div className="notification is-link is-light" key={session.sessionTitle}> 
              <p className="is-size-5"><strong>{session.sessionTitle}</strong></p>
              <br />
              <strong>Duration:</strong> {session.sessionDuration} hours
              <br />
              <strong>Start Time (UTC-5):</strong> {DateTime.fromISO(session.sessionStartAMER).toUTC().setZone('UTC-5').hour.toLocaleString("en-US", {minimumIntegerDigits:2})}:{DateTime.fromISO(session.sessionStartAMER).toUTC().setZone('UTC-5').minute.toLocaleString("en-US", {minimumIntegerDigits:2})} / April {DateTime.fromISO(session.sessionStartAMER).toUTC().setZone('UTC-5').day}
              <br />
              <strong>Local Start Time (24hr) ({DateTime.fromISO(session.sessionStartAMER).zoneName}):</strong> {DateTime.fromISO(session.sessionStartAMER).hour.toLocaleString("en-US", {minimumIntegerDigits:2})}:{DateTime.fromISO(session.sessionStartAMER).minute.toLocaleString("en-US", {minimumIntegerDigits:2})} / April {DateTime.fromISO(session.sessionStartAMER).day}
              <br />
              <ICalendarLink filename={"AWSTIPS2022 - " + session.sessionTitle + ".ics"} event={this.getMeeting(session.sessionTitle, "breakout")} >
                <button className="button is-black is-small is-responsive">
                <span><strong>Add to Calendar</strong></span>
              </button>
              </ICalendarLink>
              <br />
              <strong>Presenter(s):</strong> {session.sessionHostAMER}
              <br />
              <strong>Domain:</strong> {session.sessionDomain}
              <br />
              <strong>Session Type:</strong> {session.sessionType}
              <br />
              <br />
              <strong>Abstract:</strong> {session.sessionAbstract}
              <br />
              <br />
              {DateTime.now().toUTC().setZone('UTC-5') >= DateTime.fromISO(session.sessionStartAMER).toUTC().setZone('UTC-5').minus({ minutes: 30000 }) ?  
              <a href={session.sessionWebexLinkAMER} target="_blank" rel="noreferrer">
              <button className="button is-link">
                <span className="icon is-small">
                  <i className="fas fa-paper-plane"></i>
                </span>
                <span>Join Session</span>
              </button>
              </a>
              :
              <button className="button is-warning">
                <span className="icon is-small">
                  <i className="fas fa-paper-plane"></i>
                </span>
                <span>Available 30 minutes before session</span>
              </button>
          }
            </div> 
            : null
          ))}
          </TabPanel> 
 </Tabs>
        </div>
        </section>
      </Fragment>
    );
  }

}
