import React from 'react';

export default function Hero() {
  return (
    <section className="hero is-medium is-link has-bg-img">
      <div className="hero-body">
      <div className="container">
          <img src="tips-logo-hero-large.png" width="800" height="215" alt="AWS Training Instructor and Partner Summit 2022" />
        </div>
      </div>
    </section>
  );
}
