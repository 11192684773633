import React, { Fragment } from 'react';
import Hero from './home/Hero';
import HomeContent from './home/HomeContent';
import CountdownTimer from '../CountdownTimer'
import { DateTime } from 'luxon';

export default function Home() {

  // Leverages useCountdown hook to create countdown timer. Start time based on APJC which is the first GEO to start!! 
  const EventStart = DateTime.fromISO("2022-04-05T08:00:00.000+08:00")
  const currentTime = DateTime.now().toUTC().setZone('UTC+8')
  const timeToEventMs = EventStart.diff(currentTime).toObject()["milliseconds"]

  const currentTimeInMs = new Date().getTime();
  const timeToEvent = currentTimeInMs + timeToEventMs;

  // Test timer expired
  // const timeToEvent = 0;

  return (
    <Fragment>
      <Hero />
      <div className="box cta">
        <div className="has-text-centered">
          <div className="content is-size-5">
          <div>The <strong>AWS Training Instructor and Partner Summit (TIPS) 2022</strong> brings together the complete community of professionals that deliver and enable AWS Training across the globe. This virtual event is exclusively built for our AWS Authorized Instructors (AAIs) and AWS Training Partners — providing a global forum for you to connect with the AWS Training and Certification team, and update you on the exciting direction that we’re heading in 2022. The AWS TIPS event features a mix of leadership keynotes, breakout sessions, and workshops, allowing you to engage in the topics that are most applicable to your role and function.</div>
          <div><strong>Join us for this three-day event!</strong></div>
          <br />
          <span className="tag is-danger is-large has-text-weight-semibold">April 5-7 2022</span>
          </div>
          
          

          <div>
            <CountdownTimer targetDate={timeToEvent} />
          </div>
          </div>
          </div>
    
      
      <HomeContent />
    </Fragment>
  );
}
