import React, { Component, Fragment } from 'react';
import Agenda from './sessiondata/agenda.json';
import Keynotes from './sessiondata/agenda-keynotes.json';
import { DateTime } from 'luxon';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css'
import ICalendarLink from "react-icalendar-link"


// Sort by Date
// Agenda.sessions.sort(function(a,b) {
//   return (DateTime.fromISO(a.sessionStartAPJC).day < DateTime.fromISO(b.sessionStartAPJC).day) ? -1 : ((DateTime.fromISO(a.sessionStartAPJC).day > DateTime.fromISO(b.sessionStartAPJC).day) ? 1 : 0)
// });

Agenda.sessions.sort(function(a,b) {
  return (DateTime.fromISO(a.sessionStartAPJC).toUTC().setZone('UTC+8').hour < DateTime.fromISO(b.sessionStartAPJC).toUTC().setZone('UTC+8').hour) ? -1 : ((DateTime.fromISO(a.sessionStartAPJC).toUTC().setZone('UTC+8').hour > DateTime.fromISO(b.sessionStartAPJC).toUTC().setZone('UTC+8').hour) ? 1 : 0)
});

Keynotes.sessions.sort(function(a,b) {
  return (DateTime.fromISO(a.sessionStartAPJC).toUTC().setZone('UTC+8').hour < DateTime.fromISO(b.sessionStartAPJC).toUTC().setZone('UTC+8').hour) ? -1 : ((DateTime.fromISO(a.sessionStartAPJC).toUTC().setZone('UTC+8').hour > DateTime.fromISO(b.sessionStartAPJC).toUTC().setZone('UTC+8').hour) ? 1 : 0)
});


export default class Apjc extends Component {

  state = {
    tableData: []
  };

  componentDidMount = () => {

  }  

  getMeeting = (title, type) => {
    if (type === "keynote") { 
      let selectedSession = Keynotes.sessions.find( ({ sessionTitle }) => sessionTitle === title)
      let calevent = {
        title: "TIPS2022 Session: " + selectedSession.sessionTitle,
        description: "AWS TIPS 2022 Session: " + selectedSession.sessionTitle + "     Session Outline:    " + selectedSession.sessionAbstract,
        startTime: selectedSession.sessionStartAPJC,
        endTime: DateTime.fromISO(selectedSession.sessionStartAPJC).plus({ hours: 1 }).toUTC().setZone('UTC+8').toString(),
        location: "Webex Link: " + selectedSession.sessionWebexLinkAPJC,
      }
      return calevent
    } else {
      let selectedSession = Agenda.sessions.find( ({ sessionTitle }) => sessionTitle === title)
      let calevent = {
        title: "TIPS2022 Session: " + selectedSession.sessionTitle,
        description: "AWS TIPS 2022 Session: " + selectedSession.sessionTitle + "     Session Outline:    " + selectedSession.sessionAbstract,
        startTime: selectedSession.sessionStartAPJC,
        endTime: DateTime.fromISO(selectedSession.sessionStartAPJC).plus({ hours: 1 }).toUTC().setZone('UTC+8').toString(),
        location: "Webex Link: " + selectedSession.sessionWebexLinkAPJC,
      }
      return calevent
    }
  
  }

  render() {
    // const sortedAgenda = Agenda.sort((a,b) => { return a.sessionStartAPJC - b.sessionStartAPJC });
    return (
      <Fragment>
        <section className="section">
        <div className="container">

        <Tabs>
          <TabList>
            <Tab><strong>Keynotes & Leadership Sessions</strong></Tab>
            <Tab><strong>Day 1</strong></Tab>
            <Tab><strong>Day 2</strong></Tab>
            <Tab><strong>Day 3</strong></Tab>
          </TabList>

          <TabPanel>
        <div className="notification is-danger">
        <strong><p className="is-size-4">TIPS 2022 - Day 1 APJC Sessions (April 5)</p></strong>
        </div>
          {Keynotes.sessions.map((session) => (
              session.sessionDayAPJC === 1 && session.sessionStatusAPJC === "enabled" ?           
              <div className="notification is-grey is-light" key={session.sessionTitle}> 
              <p className="is-size-5"><strong>{session.sessionTitle}</strong></p>
              <br />
              <strong>Duration:</strong> {session.sessionDuration} hours
              <br />
              <strong>Start Time (UTC+8):</strong> {DateTime.fromISO(session.sessionStartAPJC).toUTC().setZone('UTC+8').hour.toLocaleString("en-US", {minimumIntegerDigits:2})}:{DateTime.fromISO(session.sessionStartAPJC).toUTC().setZone('UTC+8').minute.toLocaleString("en-US", {minimumIntegerDigits:2})} / April {DateTime.fromISO(session.sessionStartAPJC).toUTC().setZone('UTC+8').day}
              <br />
              <strong>Local Start Time (24hr) ({DateTime.fromISO(session.sessionStartAPJC).zoneName}):</strong> {DateTime.fromISO(session.sessionStartAPJC).hour.toLocaleString("en-US", {minimumIntegerDigits:2})}:{DateTime.fromISO(session.sessionStartAPJC).minute.toLocaleString("en-US", {minimumIntegerDigits:2})} / April {DateTime.fromISO(session.sessionStartAPJC).day}
              <br />
              <ICalendarLink filename={"AWSTIPS2022 - " + session.sessionTitle + ".ics"} event={this.getMeeting(session.sessionTitle, "keynote")} >
                <button className="button is-black is-small is-responsive">
                  <span><strong>Add to Calendar</strong></span>
                </button>
              </ICalendarLink>
              <br />
              <strong>Presenter(s):</strong> {session.sessionHostAPJC}
              <br />
              <strong>Domain:</strong> {session.sessionDomain}
              <br />
              <strong>Session Type:</strong> {session.sessionType}
              <br />
              <br />
              <strong>Abstract:</strong> {session.sessionAbstract}
              <br />
              <br />
              {DateTime.now().toUTC().setZone('UTC+8') >= DateTime.fromISO(session.sessionStartAPJC).toUTC().setZone('UTC+8').minus({ minutes: 30000 }) ?  
              <a href={session.sessionWebexLinkAPJC} target="_blank" rel="noreferrer">
              <button className="button is-danger">
                <span className="icon is-small">
                  <i className="fas fa-paper-plane"></i>
                </span>
                <span>Join Session</span>
              </button>
              </a>
              :
              <button className="button is-warning">
                <span className="icon is-small">
                  <i className="fas fa-paper-plane"></i>
                </span>
                <span>Available 30 minutes before session</span>
              </button>
          }
            </div> 
            : null
          ))}


<div className="notification is-danger">
        <strong><p className="is-size-4">TIPS 2022 - Day 2 APJC Sessions (April 6)</p></strong>
        </div>
          {Keynotes.sessions.map((session) => (
              session.sessionDayAPJC === 2 && session.sessionStatusAPJC === "enabled" ?           
              <div className="notification is-grey is-light" key={session.sessionTitle}> 
              <p className="is-size-5"><strong>{session.sessionTitle}</strong></p>
              <br />
              <strong>Duration:</strong> {session.sessionDuration} hours
              <br />
              <strong>Start Time (UTC+8):</strong> {DateTime.fromISO(session.sessionStartAPJC).toUTC().setZone('UTC+8').hour.toLocaleString("en-US", {minimumIntegerDigits:2})}:{DateTime.fromISO(session.sessionStartAPJC).toUTC().setZone('UTC+8').minute.toLocaleString("en-US", {minimumIntegerDigits:2})} / April {DateTime.fromISO(session.sessionStartAPJC).toUTC().setZone('UTC+8').day}
              <br />
              <strong>Local Start Time (24hr) ({DateTime.fromISO(session.sessionStartAPJC).zoneName}):</strong> {DateTime.fromISO(session.sessionStartAPJC).hour.toLocaleString("en-US", {minimumIntegerDigits:2})}:{DateTime.fromISO(session.sessionStartAPJC).minute.toLocaleString("en-US", {minimumIntegerDigits:2})} / April {DateTime.fromISO(session.sessionStartAPJC).day}
              <br />
              <ICalendarLink filename={"AWSTIPS2022 - " + session.sessionTitle + ".ics"} event={this.getMeeting(session.sessionTitle, "keynote")} >
                <button className="button is-black is-small is-responsive">
                  <span><strong>Add to Calendar</strong></span>
                </button>
              </ICalendarLink>
              <br />
              <strong>Presenter(s):</strong> {session.sessionHostAPJC}
              <br />
              <strong>Domain:</strong> {session.sessionDomain}
              <br />
              <strong>Session Type:</strong> {session.sessionType}
              <br />
              <br />
              <strong>Abstract:</strong> {session.sessionAbstract}
              <br />
              <br />
              {DateTime.now().toUTC().setZone('UTC+8') >= DateTime.fromISO(session.sessionStartAPJC).toUTC().setZone('UTC+8').minus({ minutes: 30000 }) ?  
              <a href={session.sessionWebexLinkAPJC} target="_blank" rel="noreferrer">
              <button className="button is-danger">
                <span className="icon is-small">
                  <i className="fas fa-paper-plane"></i>
                </span>
                <span>Join Session</span>
              </button>
              </a>
              :
              <button className="button is-warning">
                <span className="icon is-small">
                  <i className="fas fa-paper-plane"></i>
                </span>
                <span>Available 30 minutes before session</span>
              </button>
          }
            </div> 
            : null
          ))}


{/* <div className="notification is-danger">
        <strong><p className="is-size-4">TIPS 2022 - Day 3 APJC Sessions (April 7)</p></strong>
        </div> */}
          {Keynotes.sessions.map((session) => (
              session.sessionDayAPJC === 3 && session.sessionStatusAPJC === "enabled" ?           
              <div className="notification is-grey is-light" key={session.sessionTitle}> 
              <p className="is-size-5"><strong>{session.sessionTitle}</strong></p>
              <br />
              <strong>Duration:</strong> {session.sessionDuration} hours
              <br />
              <strong>Start Time (UTC+8):</strong> {DateTime.fromISO(session.sessionStartAPJC).toUTC().setZone('UTC+8').hour.toLocaleString("en-US", {minimumIntegerDigits:2})}:{DateTime.fromISO(session.sessionStartAPJC).toUTC().setZone('UTC+8').minute.toLocaleString("en-US", {minimumIntegerDigits:2})} / April {DateTime.fromISO(session.sessionStartAPJC).toUTC().setZone('UTC+8').day}
              <br />
              <strong>Local Start Time (24hr) ({DateTime.fromISO(session.sessionStartAPJC).zoneName}):</strong> {DateTime.fromISO(session.sessionStartAPJC).hour.toLocaleString("en-US", {minimumIntegerDigits:2})}:{DateTime.fromISO(session.sessionStartAPJC).minute.toLocaleString("en-US", {minimumIntegerDigits:2})} / April {DateTime.fromISO(session.sessionStartAPJC).day}
              <br />
              <ICalendarLink filename={"AWSTIPS2022 - " + session.sessionTitle + ".ics"} event={this.getMeeting(session.sessionTitle, "keynote")} >
                <button className="button is-black is-small is-responsive">
                  <span><strong>Add to Calendar</strong></span>
                </button>
              </ICalendarLink>
              <br />
              <strong>Presenter(s):</strong> {session.sessionHostAPJC}
              <br />
              <strong>Domain:</strong> {session.sessionDomain}
              <br />
              <strong>Session Type:</strong> {session.sessionType}
              <br />
              <br />
              <strong>Abstract:</strong> {session.sessionAbstract}
              <br />
              <br />
              {DateTime.now().toUTC().setZone('UTC+8') >= DateTime.fromISO(session.sessionStartAPJC).toUTC().setZone('UTC+8').minus({ minutes: 30000 }) ?  
              <a href={session.sessionWebexLinkAPJC} target="_blank" rel="noreferrer">
              <button className="button is-danger">
                <span className="icon is-small">
                  <i className="fas fa-paper-plane"></i>
                </span>
                <span>Join Session</span>
              </button>
              </a>
              :
              <button className="button is-warning">
                <span className="icon is-small">
                  <i className="fas fa-paper-plane"></i>
                </span>
                <span>Available 30 minutes before session</span>
              </button>
          }
            </div> 
            : null
          ))}
          </TabPanel>


        <TabPanel>
        <div className="notification is-danger">
        <strong><p className="is-size-4">TIPS 2022 - Day 1 APJC Sessions (April 5)</p></strong>
        </div>
          {Agenda.sessions.map((session) => (
              session.sessionDayAPJC === 1 && session.sessionStatusAPJC === "enabled" ?           
              <div className="notification is-grey is-light" key={session.sessionTitle}> 
              <p className="is-size-5"><strong>{session.sessionTitle}</strong></p>
              <br />
              <strong>Duration:</strong> {session.sessionDuration} hours
              <br />
              <strong>Start Time (UTC+8):</strong> {DateTime.fromISO(session.sessionStartAPJC).toUTC().setZone('UTC+8').hour.toLocaleString("en-US", {minimumIntegerDigits:2})}:{DateTime.fromISO(session.sessionStartAPJC).toUTC().setZone('UTC+8').minute.toLocaleString("en-US", {minimumIntegerDigits:2})} / April {DateTime.fromISO(session.sessionStartAPJC).toUTC().setZone('UTC+8').day}
              <br />
              <strong>Local Start Time (24hr) ({DateTime.fromISO(session.sessionStartAPJC).zoneName}):</strong> {DateTime.fromISO(session.sessionStartAPJC).hour.toLocaleString("en-US", {minimumIntegerDigits:2})}:{DateTime.fromISO(session.sessionStartAPJC).minute.toLocaleString("en-US", {minimumIntegerDigits:2})} / April {DateTime.fromISO(session.sessionStartAPJC).day}
              <br />
              <ICalendarLink filename={"AWSTIPS2022 - " + session.sessionTitle + ".ics"} event={this.getMeeting(session.sessionTitle, "breakout")} >
                <button className="button is-black is-small is-responsive">
                  <span><strong>Add to Calendar</strong></span>
                </button>
              </ICalendarLink>
              <br />
              <strong>Presenter(s):</strong> {session.sessionHostAPJC}
              <br />
              <strong>Domain:</strong> {session.sessionDomain}
              <br />
              <strong>Session Type:</strong> {session.sessionType}
              <br />
              <br />
              <strong>Abstract:</strong> {session.sessionAbstract}
              <br />
              <br />
              {DateTime.now().toUTC().setZone('UTC+8') >= DateTime.fromISO(session.sessionStartAPJC).toUTC().setZone('UTC+8').minus({ minutes: 30000 }) ?  
              <a href={session.sessionWebexLinkAPJC} target="_blank" rel="noreferrer">
              <button className="button is-danger">
                <span className="icon is-small">
                  <i className="fas fa-paper-plane"></i>
                </span>
                <span>Join Session</span>
              </button>
              </a>
              :
              <button className="button is-warning">
                <span className="icon is-small">
                  <i className="fas fa-paper-plane"></i>
                </span>
                <span>Available 30 minutes before session</span>
              </button>
          }
            </div> 
            : null
          ))}
          </TabPanel>



          <TabPanel>
            <div className="notification is-danger">
              <strong><p className="is-size-4">TIPS 2022 - Day 2 APJC Sessions (April 6)</p></strong>
            </div>
            {Agenda.sessions.map((session) => (
              session.sessionDayAPJC === 2 && session.sessionStatusAPJC === "enabled" ?           
              <div className="notification is-grey is-light" key={session.sessionTitle}> 
              <p className="is-size-5"><strong>{session.sessionTitle}</strong></p>
              <br />
              <strong>Duration:</strong> {session.sessionDuration} hours
              <br />
              <strong>Start Time (UTC+8):</strong> {DateTime.fromISO(session.sessionStartAPJC).toUTC().setZone('UTC+8').hour.toLocaleString("en-US", {minimumIntegerDigits:2})}:{DateTime.fromISO(session.sessionStartAPJC).toUTC().setZone('UTC+8').minute.toLocaleString("en-US", {minimumIntegerDigits:2})} / April {DateTime.fromISO(session.sessionStartAPJC).toUTC().setZone('UTC+8').day}
              <br />
              <strong>Local Start Time (24hr) ({DateTime.fromISO(session.sessionStartAPJC).zoneName}):</strong> {DateTime.fromISO(session.sessionStartAPJC).hour.toLocaleString("en-US", {minimumIntegerDigits:2})}:{DateTime.fromISO(session.sessionStartAPJC).minute.toLocaleString("en-US", {minimumIntegerDigits:2})} / April {DateTime.fromISO(session.sessionStartAPJC).day}
              <br />
              <ICalendarLink filename={"AWSTIPS2022 - " + session.sessionTitle + ".ics"} event={this.getMeeting(session.sessionTitle, "breakout")} >
                <button className="button is-black is-small is-responsive">
                  <span><strong>Add to Calendar</strong></span>
                </button>
              </ICalendarLink>
              <br />
              <strong>Presenter(s):</strong> {session.sessionHostAPJC}
              <br />
              <strong>Domain:</strong> {session.sessionDomain}
              <br />
              <strong>Session Type:</strong> {session.sessionType}
              <br />
              <br />
              <strong>Abstract:</strong> {session.sessionAbstract}
              <br />
              <br />
              {DateTime.now().toUTC().setZone('UTC+8') >= DateTime.fromISO(session.sessionStartAPJC).toUTC().setZone('UTC+8').minus({ minutes: 30000 }) ?  
              <a href={session.sessionWebexLinkAPJC} target="_blank" rel="noreferrer">
              <button className="button is-danger">
                <span className="icon is-small">
                  <i className="fas fa-paper-plane"></i>
                </span>
                <span>Join Session</span>
              </button>
              </a>
              :
              <button className="button is-warning">
                <span className="icon is-small">
                  <i className="fas fa-paper-plane"></i>
                </span>
                <span>Available 30 minutes before session</span>
              </button>
          }
            </div> 
            : null
          ))}
          </TabPanel>
          <TabPanel>
            <div className="notification is-danger">
              <strong><p className="is-size-4">TIPS 2022 - Day 3 APJC Sessions (April 7)</p></strong>
            </div>
            {Agenda.sessions.map((session) => (
              session.sessionDayAPJC === 3 && session.sessionStatusAPJC === "enabled" ?           
              <div className="notification is-grey is-light" key={session.sessionTitle}> 
              <p className="is-size-5"><strong>{session.sessionTitle}</strong></p>
              <br />
              <strong>Duration:</strong> {session.sessionDuration} hours
              <br />
              <strong>Start Time (UTC+8):</strong> {DateTime.fromISO(session.sessionStartAPJC).toUTC().setZone('UTC+8').hour.toLocaleString("en-US", {minimumIntegerDigits:2})}:{DateTime.fromISO(session.sessionStartAPJC).toUTC().setZone('UTC+8').minute.toLocaleString("en-US", {minimumIntegerDigits:2})} / April {DateTime.fromISO(session.sessionStartAPJC).toUTC().setZone('UTC+8').day}
              <br />
              <strong>Local Start Time (24hr) ({DateTime.fromISO(session.sessionStartAPJC).zoneName}):</strong> {DateTime.fromISO(session.sessionStartAPJC).hour.toLocaleString("en-US", {minimumIntegerDigits:2})}:{DateTime.fromISO(session.sessionStartAPJC).minute.toLocaleString("en-US", {minimumIntegerDigits:2})} / April {DateTime.fromISO(session.sessionStartAPJC).day}
              <br />
              <ICalendarLink filename={"AWSTIPS2022 - " + session.sessionTitle + ".ics"} event={this.getMeeting(session.sessionTitle, "breakout")} >
                <button className="button is-black is-small is-responsive">
                  <span><strong>Add to Calendar</strong></span>
                </button>
              </ICalendarLink>
              <br />
              <strong>Presenter(s):</strong> {session.sessionHostAPJC}
              <br />
              <strong>Domain:</strong> {session.sessionDomain}
              <br />
              <strong>Session Type:</strong> {session.sessionType}
              <br />
              <br />
              <strong>Abstract:</strong> {session.sessionAbstract}
              <br />
              <br />
              {DateTime.now().toUTC().setZone('UTC+8') >= DateTime.fromISO(session.sessionStartAPJC).toUTC().setZone('UTC+8').minus({ minutes: 30000 }) ?  
              <a href={session.sessionWebexLinkAPJC} target="_blank" rel="noreferrer">
              <button className="button is-danger">
                <span className="icon is-small">
                  <i className="fas fa-paper-plane"></i>
                </span>
                <span>Join Session</span>
              </button>
              </a>
              :
              <button className="button is-warning">
                <span className="icon is-small">
                  <i className="fas fa-paper-plane"></i>
                </span>
                <span>Available 30 minutes before session</span>
              </button>
          }
            </div> 
            : null
          ))}
          </TabPanel>

    </Tabs>
        </div>
        </section>
      </Fragment>
    );
  }

}
