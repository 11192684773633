import React, { Component } from 'react';


export default class Navbar extends Component {

  render() {

    return (
      <nav className="navbar" role="navigation" aria-label="main navigation">
        <div className="navbar-brand">
          <a className="navbar-item" href="/">
            {/* <img src="tips-logo.png" width="160" height="43" alt="aws logo" /> */}
            <img src="tc-logo.png" width="140" height="35" alt="aws logo" />
          </a>
        </div>

        <div id="navbarBasicExample" className="navbar-menu">
          <div className="navbar-start">
          <a href="/" className="navbar-item">
            Home
          </a>
            <div class="navbar-item has-dropdown is-hoverable">
              <a href="/agenda" class="navbar-link">
                Agenda
              </a>
              <div class="navbar-dropdown">
              <a href="/agenda" class="navbar-link">
                <strong>High Level Agenda</strong>
              </a>
              <hr className="navbar-divider"></hr>
              <a href="/apjc" class="navbar-item">
                Session Details APJC
              </a>
              <a href="/europe" class="navbar-item">
                Session Details EMEA
              </a>
              <a href="/americas" class="navbar-item">
                Session Details AMER
              </a>
              <a href="/atp" class="navbar-item">
                Session Details ATP
              </a>
              </div>
            </div>
            <a href="/faq" className="navbar-item">
              FAQ
            </a>

          </div>

          <div className="navbar-end">
          
            <div className="navbar-item">
            
            </div>
          </div>
        </div>
      </nav>
    );
  }
}
