import React from 'react';

export default function Footer() {
  return (
    <footer className="footer">
      <div className="content has-text-centered">
        <p>
        © 2022, Amazon Web Services, Inc. or its affiliates. All rights reserved.
        </p>
      </div>
    </footer>
  );
}
