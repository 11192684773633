import React, { Component, Fragment } from 'react';

export default class Faq extends Component {

  state = {
    tableData: []
  };



  componentDidMount = () => {

  }  

  render() {
    return (
      <Fragment>
        <section className="section">
          <div className="container">
            <div className="columns">
              <div className="column is-one-third">
              <h1>Frequently Asked Questions</h1>
              <br />
              <img src="faq-image.png" alt="AAI" width="385" />
              </div>
            <div className="column">
              <br />
              <p className="is-size-5"><strong>Q: What is AWS TIPS?</strong></p>
              <p className="is-size-5">A: AWS TIPS is a three-day, fully virtual event that brings together the complete community of professionals that deliver and enable AWS Training across the globe, including the AWS Authorized Instructor (AAIs) and AWS Training Partner communities.</p>
              <br />
              <p className="is-size-5"><strong>Q: When and where will AWS TIPS be in 2022?</strong></p>
              <p className="is-size-5">A: AWS TIPS will be from <strong>April 5-7, 2022</strong> and will be hosted virtually. Please register to reserve your space.</p>
              <br />
              <p className="is-size-5"><strong>Q: How do I know which track I should be in?</strong></p>
              <p className="is-size-5">A: If you are an instructor delivering AWS Training, including AWS Authorized Instructors (AAIs), AAI candidates, and AWS Partner Trainers, please attend the AAI Track. If you are in a leadership, sales, marketing, or operations role within an AWS Partner, please attend the ATP Track.</p>
              <br />
              <p className="is-size-5"><strong>Q: Can I attend a session in a different region?</strong></p>
              <p className="is-size-5">A: Yes, sessions repeat across three time zones and we encourage you to attend when most convenient for you.</p>
              <br />
              <p className="is-size-5"><strong>Q: Will the sessions be recorded?</strong></p>
              <p className="is-size-5">A: Yes, session recordings will be available for registered attendees after the event.</p>
              <br />
              <p className="is-size-5"><strong>Q: How do I get more information?</strong></p>
              <p className="is-size-5">A: Please email <a href="mailto:aws-tips-2022@amazon.com">Event Support</a> with any other questions.</p>
              <br />


            </div>

            </div>
          </div>
        </section>
      </Fragment>
    );
  }

}
