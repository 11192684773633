import React, { Component } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faEdit } from '@fortawesome/free-solid-svg-icons';

import './App.css';

import Navbar from './components/Navbar';
import Home from './components/Home';
import Agenda from './components/Agenda';
import Americas from './components/Americas';
import Europe from './components/Europe';
import Apjc from './components/Apjc';
import Atp from './components/Atp';
import Faq from './components/Faq';
import Footer from './components/Footer';

library.add(faEdit);

class App extends Component {

  state = {
    user: null
  }

  async componentDidMount() {

  }

  render() {
    return (
      <div className="App">
          <div>
            <Navbar/>
            <BrowserRouter>
              <Routes>
                <Route exact path="/" element={<Home/>} />
                <Route exact path="/agenda" element={<Agenda/>} />
                <Route exact path="/europe" element={<Europe/>} />
                <Route exact path="/americas" element={<Americas/>} />
                <Route exact path="/apjc" element={<Apjc/>} />
                <Route exact path="/faq" element={<Faq/>} />
                <Route exact path="/atp" element={<Atp/>} />
              </Routes>
            </BrowserRouter>
            <Footer />
          </div>
      </div>
    );
  }
}

export default App;
