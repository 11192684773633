import React, { Component, Fragment } from 'react';
import Agenda from './sessiondata/agenda-atp.json';
import Keynotes from './sessiondata/agenda-keynotes.json';
import { DateTime } from 'luxon';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css'
import ICalendarLink from "react-icalendar-link"



Agenda.sessions.sort(function(a,b) {
  return (DateTime.fromISO(a.sessionStartAMER).toUTC().setZone('UTC-5').hour < DateTime.fromISO(b.sessionStartAMER).toUTC().setZone('UTC-5').hour) ? -1 : ((DateTime.fromISO(a.sessionStartAMER).toUTC().setZone('UTC-5').hour > DateTime.fromISO(b.sessionStartAMER).toUTC().setZone('UTC-5').hour) ? 1 : 0)
});

Keynotes.sessions.sort(function(a,b) {
  return (DateTime.fromISO(a.sessionStartAMER).toUTC().setZone('UTC-5').hour < DateTime.fromISO(b.sessionStartAMER).toUTC().setZone('UTC-5').hour) ? -1 : ((DateTime.fromISO(a.sessionStartAMER).toUTC().setZone('UTC-5').hour > DateTime.fromISO(b.sessionStartAMER).toUTC().setZone('UTC-5').hour) ? 1 : 0)
});

export default class Atp extends Component {

  state = {
    tableData: []
  };

  componentDidMount = () => {

  }  

  getMeeting = (title, type, geo) => {
    if (type === "keynote" && geo === "amer") { 
      let selectedSession = Keynotes.sessions.find( ({ sessionTitle }) => sessionTitle === title)
      let calevent = {
        title: "TIPS2022 Session: " + selectedSession.sessionTitle,
        description: "AWS TIPS 2022 Session: " + selectedSession.sessionTitle + "     Session Outline:    " + selectedSession.sessionAbstract,
        startTime: selectedSession.sessionStartAMER,
        endTime: DateTime.fromISO(selectedSession.sessionStartAMER).plus({ hours: 1 }).toUTC().setZone('UTC-5').toString(),
        location: "Webex Link: " + selectedSession.sessionWebexLinkAMER,
      }
      return calevent
    } else if (type === "keynote" && geo === "emea") {
      let selectedSession = Keynotes.sessions.find( ({ sessionTitle }) => sessionTitle === title)
      let calevent = {
        title: "TIPS2022 Session: " + selectedSession.sessionTitle,
        description: "AWS TIPS 2022 Session: " + selectedSession.sessionTitle + "     Session Outline:    " + selectedSession.sessionAbstract,
        startTime: selectedSession.sessionStartEMEA,
        endTime: DateTime.fromISO(selectedSession.sessionStartEMEA).plus({ hours: 1 }).toUTC().setZone('UTC+1').toString(),
        location: "Webex Link: " + selectedSession.sessionWebexLinkEMEA,
      }
      return calevent
    } else if (type === "keynote" && geo === "apjc") {
      let selectedSession = Keynotes.sessions.find( ({ sessionTitle }) => sessionTitle === title)
      let calevent = {
        title: "TIPS2022 Session: " + selectedSession.sessionTitle,
        description: "AWS TIPS 2022 Session: " + selectedSession.sessionTitle + "     Session Outline:    " + selectedSession.sessionAbstract,
        startTime: selectedSession.sessionStartAPJC,
        endTime: DateTime.fromISO(selectedSession.sessionStartAPJC).plus({ hours: 1 }).toUTC().setZone('UTC+8').toString(),
        location: "Webex Link: " + selectedSession.sessionWebexLinkAPJC,
      }
      return calevent
    } else if (type === "breakout" && geo === "amer") {
      let selectedSession = Agenda.sessions.find( ({ sessionTitle }) => sessionTitle === title)
      let calevent = {
        title: "TIPS2022 Session: " + selectedSession.sessionTitle,
        description: "AWS TIPS 2022 Session: " + selectedSession.sessionTitle + "     Session Outline:    " + selectedSession.sessionAbstract,
        startTime: selectedSession.sessionStartAMER,
        endTime: DateTime.fromISO(selectedSession.sessionStartAMER).plus({ hours: 1 }).toUTC().setZone('UTC-5').toString(),
        location: "Webex Link: " + selectedSession.sessionWebexLinkAMER,
      }
      return calevent
    } else if (type === "breakout" && geo === "emea") {
      let selectedSession = Agenda.sessions.find( ({ sessionTitle }) => sessionTitle === title)
      let calevent = {
        title: "TIPS2022 Session: " + selectedSession.sessionTitle,
        description: "AWS TIPS 2022 Session: " + selectedSession.sessionTitle + "     Session Outline:    " + selectedSession.sessionAbstract,
        startTime: selectedSession.sessionStartEMEA,
        endTime: DateTime.fromISO(selectedSession.sessionStartEMEA).plus({ hours: 1 }).toUTC().setZone('UTC+1').toString(),
        location: "Webex Link: " + selectedSession.sessionWebexLinkEMEA,
      }
      return calevent
    } else if (type === "breakout" && geo === "apjc") {
      let selectedSession = Agenda.sessions.find( ({ sessionTitle }) => sessionTitle === title)
      let calevent = {
        title: "TIPS2022 Session: " + selectedSession.sessionTitle,
        description: "AWS TIPS 2022 Session: " + selectedSession.sessionTitle + "     Session Outline:    " + selectedSession.sessionAbstract,
        startTime: selectedSession.sessionStartAPJC,
        endTime: DateTime.fromISO(selectedSession.sessionStartAPJC).plus({ hours: 1 }).toUTC().setZone('UTC+8').toString(),
        location: "Webex Link: " + selectedSession.sessionWebexLinkAPJC,
      }
      return calevent
    }
    
    else {
      return "ERROR"
    }
  
  }

  render() {
    return (
      <Fragment>
        <section className="section">
        <div className="container">

        <Tabs>
          <TabList>
            <Tab><strong>Keynotes & Leadership Sessions</strong></Tab>
            <Tab><strong>Day 1</strong></Tab>
            <Tab><strong>Day 2</strong></Tab>
          </TabList>

          <TabPanel> 
        <div className="notification is-black">
        <strong><p className="is-size-4">TIPS 2022 - Day 1 - ATP Focused Sessions (April 5)</p></strong>
        </div>
          {Keynotes.sessions.map((session) => (
              session.sessionDayEMEA === 1 ?
              <div className="notification is-link is-light" key={session.sessionTitle}> 
              <p className="is-size-5"><strong>{session.sessionTitle}</strong></p>
              <br />
              <strong>Duration:</strong> {session.sessionDuration} hours
              <br />
              <strong>Start Time (AMER UTC-5 / EMEA UTC+1 / APJC UTC+8):</strong> {DateTime.fromISO(session.sessionStartAMER).toUTC().setZone('UTC-5').hour.toLocaleString("en-US", {minimumIntegerDigits:2})}:{DateTime.fromISO(session.sessionStartAMER).toUTC().setZone('UTC-5').minute.toLocaleString("en-US", {minimumIntegerDigits:2})} / April {DateTime.fromISO(session.sessionStartAMER).toUTC().setZone('UTC-5').day}
              <br />
              <strong>Local Start Time AMER (24hr) ({DateTime.fromISO(session.sessionStartAMER).zoneName}):</strong> {DateTime.fromISO(session.sessionStartAMER).hour.toLocaleString("en-US", {minimumIntegerDigits:2})}:{DateTime.fromISO(session.sessionStartAMER).minute.toLocaleString("en-US", {minimumIntegerDigits:2})} / April {DateTime.fromISO(session.sessionStartAMER).day}
              <br />
              <ICalendarLink filename={"AWSTIPS2022 - " + session.sessionTitle + ".ics"} event={this.getMeeting(session.sessionTitle, "keynote", "amer")} >
                <button className="button is-black is-small is-responsive">
                  <span><strong>Add AMER Session to Calendar</strong></span>
                </button>
              </ICalendarLink>
              <br />
              <strong>Local Start Time EMEA (24hr) ({DateTime.fromISO(session.sessionStartEMEA).zoneName}):</strong> {DateTime.fromISO(session.sessionStartEMEA).hour.toLocaleString("en-US", {minimumIntegerDigits:2})}:{DateTime.fromISO(session.sessionStartEMEA).minute.toLocaleString("en-US", {minimumIntegerDigits:2})} / April {DateTime.fromISO(session.sessionStartEMEA).day}
              <br />
              <ICalendarLink filename={"AWSTIPS2022 - " + session.sessionTitle + ".ics"} event={this.getMeeting(session.sessionTitle, "keynote", "emea")} >
                <button className="button is-black is-small is-responsive">
                  <span><strong>Add EMEA Session to Calendar</strong></span>
                </button>
              </ICalendarLink>
              <br />
              <strong>Local Start Time APJC (24hr) ({DateTime.fromISO(session.sessionStartAPJC).zoneName}):</strong> {DateTime.fromISO(session.sessionStartAPJC).hour.toLocaleString("en-US", {minimumIntegerDigits:2})}:{DateTime.fromISO(session.sessionStartAPJC).minute.toLocaleString("en-US", {minimumIntegerDigits:2})} / April {DateTime.fromISO(session.sessionStartAPJC).day}
              <br />
              <ICalendarLink filename={"AWSTIPS2022 - " + session.sessionTitle + ".ics"} event={this.getMeeting(session.sessionTitle, "keynote", "apjc")} >
                <button className="button is-black is-small is-responsive">
                  <span><strong>Add APJC Session to Calendar</strong></span>
                </button>
              </ICalendarLink>
              <br />
              <strong>Presenter(s):</strong> {session.sessionHostAMER}
              <br />
              <strong>Domain:</strong> {session.sessionDomain}
              <br />
              <strong>Session Type:</strong> {session.sessionType}
              <br />
              <br />
              <strong>Abstract:</strong> {session.sessionAbstract}
              <br />
              <br />
              {DateTime.now().toUTC().setZone('UTC-5') >= DateTime.fromISO(session.sessionStartAMER).toUTC().setZone('UTC-5').minus({ minutes: 30000 }) || DateTime.now().toUTC().setZone('UTC+1') >= DateTime.fromISO(session.sessionStartAMER).toUTC().setZone('UTC+1').minus({ minutes: 30000 }) || DateTime.now().toUTC().setZone('UTC+8') >= DateTime.fromISO(session.sessionStartAMER).toUTC().setZone('UTC+8').minus({ minutes: 30000 })?  
              <div className="buttons is-centered">
                  <p>
                  <a href={session.sessionWebexLinkAMER} target="_blank" rel="noreferrer">
                  <button className="button is-link is-responsive">
                    <span className="icon is-small">
                      <i className="fas fa-paper-plane"></i>
                    </span>
                    <span>AMER Session</span>
                  </button>
                  </a>
                  </p>
    
                  <p>
                  <a href={session.sessionWebexLinkEMEA} target="_blank" rel="noreferrer">
                  <button className="button is-primary is-responsive">
                    <span className="icon is-small">
                      <i className="fas fa-paper-plane"></i>
                    </span>
                    <span>EMEA Session</span>
                  </button>
                  </a>
                  </p>
    
                  <p>
                  <a href={session.sessionWebexLinkAPJC} target="_blank" rel="noreferrer">
                  <button className="button is-danger is-responsive">
                    <span className="icon is-small">
                      <i className="fas fa-paper-plane"></i>
                    </span>
                    <span>APJC Session</span>
                  </button>
                  </a>
                  </p>
                  </div>

                  :
                  <button className="button is-warning">
                    <span className="icon is-small">
                      <i className="fas fa-paper-plane"></i>
                    </span>
                    <span>Available 30 minutes before session</span>
                  </button>
          }
            </div> 
            : null
          ))}


<div className="notification is-black">
        <strong><p className="is-size-4">TIPS 2022 - Day 2 - ATP Focused Sessions (April 6)</p></strong>
        </div>
          {Keynotes.sessions.map((session) => (
              session.sessionDayEMEA === 2 ?           
              <div className="notification is-link is-light" key={session.sessionTitle}> 
              <p className="is-size-5"><strong>{session.sessionTitle}</strong></p>
              <br />
              <strong>Duration:</strong> {session.sessionDuration} hours
              <br />
              <strong>Start Time (AMER UTC-5 / EMEA UTC+1 / APJC UTC+8):</strong> {DateTime.fromISO(session.sessionStartAMER).toUTC().setZone('UTC-5').hour.toLocaleString("en-US", {minimumIntegerDigits:2})}:{DateTime.fromISO(session.sessionStartAMER).toUTC().setZone('UTC-5').minute.toLocaleString("en-US", {minimumIntegerDigits:2})} / April {DateTime.fromISO(session.sessionStartAMER).toUTC().setZone('UTC-5').day}
              <br />
              <strong>Local Start Time AMER (24hr) ({DateTime.fromISO(session.sessionStartAMER).zoneName}):</strong> {DateTime.fromISO(session.sessionStartAMER).hour.toLocaleString("en-US", {minimumIntegerDigits:2})}:{DateTime.fromISO(session.sessionStartAMER).minute.toLocaleString("en-US", {minimumIntegerDigits:2})} / April {DateTime.fromISO(session.sessionStartAMER).day}
              <br />
              <ICalendarLink filename={"AWSTIPS2022 - " + session.sessionTitle + ".ics"} event={this.getMeeting(session.sessionTitle, "keynote", "amer")} >
                <button className="button is-black is-small is-responsive">
                  <span><strong>Add AMER Session to Calendar</strong></span>
                </button>
              </ICalendarLink>
              <br />
              <strong>Local Start Time EMEA (24hr) ({DateTime.fromISO(session.sessionStartEMEA).zoneName}):</strong> {DateTime.fromISO(session.sessionStartEMEA).hour.toLocaleString("en-US", {minimumIntegerDigits:2})}:{DateTime.fromISO(session.sessionStartEMEA).minute.toLocaleString("en-US", {minimumIntegerDigits:2})} / April {DateTime.fromISO(session.sessionStartEMEA).day}
              <br />
              <ICalendarLink filename={"AWSTIPS2022 - " + session.sessionTitle + ".ics"} event={this.getMeeting(session.sessionTitle, "keynote", "emea")} >
                <button className="button is-black is-small is-responsive">
                  <span><strong>Add EMEA Session to Calendar</strong></span>
                </button>
              </ICalendarLink>
              <br />
              <strong>Local Start Time APJC (24hr) ({DateTime.fromISO(session.sessionStartAPJC).zoneName}):</strong> {DateTime.fromISO(session.sessionStartAPJC).hour.toLocaleString("en-US", {minimumIntegerDigits:2})}:{DateTime.fromISO(session.sessionStartAPJC).minute.toLocaleString("en-US", {minimumIntegerDigits:2})} / April {DateTime.fromISO(session.sessionStartAPJC).day}
              <br />
              <ICalendarLink filename={"AWSTIPS2022 - " + session.sessionTitle + ".ics"} event={this.getMeeting(session.sessionTitle, "keynote", "apjc")} >
                <button className="button is-black is-small is-responsive">
                  <span><strong>Add APJC Session to Calendar</strong></span>
                </button>
              </ICalendarLink>
              <br />
              <strong>Presenter(s):</strong> {session.sessionHostAMER}
              <br />
              <strong>Domain:</strong> {session.sessionDomain}
              <br />
              <strong>Session Type:</strong> {session.sessionType}
              <br />
              <br />
              <strong>Abstract:</strong> {session.sessionAbstract}
              <br />
              <br />
              {DateTime.now().toUTC().setZone('UTC-5') >= DateTime.fromISO(session.sessionStartAMER).toUTC().setZone('UTC-5').minus({ minutes: 30000 }) || DateTime.now().toUTC().setZone('UTC+1') >= DateTime.fromISO(session.sessionStartAMER).toUTC().setZone('UTC+1').minus({ minutes: 30000 }) || DateTime.now().toUTC().setZone('UTC+8') >= DateTime.fromISO(session.sessionStartAMER).toUTC().setZone('UTC+8').minus({ minutes: 30000 })?  
                            <div className="buttons is-centered">
                            <p>
                          <a href={session.sessionWebexLinkAMER} target="_blank" rel="noreferrer">
                          <button className="button is-link is-responsive">
                            <span className="icon is-small">
                              <i className="fas fa-paper-plane"></i>
                            </span>
                            <span>AMER Session</span>
                          </button>
                          </a>
                          </p>
            
                          <p>
                          <a href={session.sessionWebexLinkEMEA} target="_blank" rel="noreferrer">
                          <button className="button is-primary is-responsive">
                            <span className="icon is-small">
                              <i className="fas fa-paper-plane"></i>
                            </span>
                            <span>EMEA Session</span>
                          </button>
                          </a>
                          </p>
            
                          <p>
                          <a href={session.sessionWebexLinkAPJC} target="_blank" rel="noreferrer">
                          <button className="button is-danger is-responsive">
                            <span className="icon is-small">
                              <i className="fas fa-paper-plane"></i>
                            </span>
                            <span>Join APJC Session</span>
                          </button>
                          </a>
                          </p>
                          </div>

                          
                          :
                          <button className="button is-warning">
                            <span className="icon is-small">
                              <i className="fas fa-paper-plane"></i>
                            </span>
                            <span>Available 30 minutes before session</span>
                          </button>
          }
            </div> 
            : null
          ))}

          </TabPanel>

        <TabPanel> 
        <div className="notification is-black">
        <strong><p className="is-size-4">TIPS 2022 - Day 1 - ATP Focused Sessions (April 5)</p></strong>
        </div>
          {Agenda.sessions.map((session) => (
              session.sessionDayEMEA === 1 ?           
              <div className="notification is-link is-light" key={session.sessionTitle}> 
              <p className="is-size-5"><strong>{session.sessionTitle}</strong></p>
              <br />
              <strong>Duration:</strong> {session.sessionDuration} hours
              <br />
              <strong>Start Time (AMER UTC-5 / EMEA UTC+1 / APJC UTC+8):</strong> {DateTime.fromISO(session.sessionStartAMER).toUTC().setZone('UTC-5').hour.toLocaleString("en-US", {minimumIntegerDigits:2})}:{DateTime.fromISO(session.sessionStartAMER).toUTC().setZone('UTC-5').minute.toLocaleString("en-US", {minimumIntegerDigits:2})} / April {DateTime.fromISO(session.sessionStartAMER).toUTC().setZone('UTC-5').day}
              <br />
              <strong>Local Start Time AMER (24hr) ({DateTime.fromISO(session.sessionStartAMER).zoneName}):</strong> {DateTime.fromISO(session.sessionStartAMER).hour.toLocaleString("en-US", {minimumIntegerDigits:2})}:{DateTime.fromISO(session.sessionStartAMER).minute.toLocaleString("en-US", {minimumIntegerDigits:2})} / April {DateTime.fromISO(session.sessionStartAMER).day}
              <br />
              <ICalendarLink filename={"AWSTIPS2022 - " + session.sessionTitle + ".ics"} event={this.getMeeting(session.sessionTitle, "breakout", "amer")} >
                <button className="button is-black is-small is-responsive">
                  <span><strong>Add AMER Session to Calendar</strong></span>
                </button>
              </ICalendarLink>
              <br />
              <strong>Local Start Time EMEA (24hr) ({DateTime.fromISO(session.sessionStartEMEA).zoneName}):</strong> {DateTime.fromISO(session.sessionStartEMEA).hour.toLocaleString("en-US", {minimumIntegerDigits:2})}:{DateTime.fromISO(session.sessionStartEMEA).minute.toLocaleString("en-US", {minimumIntegerDigits:2})} / April {DateTime.fromISO(session.sessionStartEMEA).day}
              <br />
              <ICalendarLink filename={"AWSTIPS2022 - " + session.sessionTitle + ".ics"} event={this.getMeeting(session.sessionTitle, "breakout", "emea")} >
                <button className="button is-black is-small is-responsive">
                  <span><strong>Add EMEA Session to Calendar</strong></span>
                </button>
              </ICalendarLink>
              <br />
              <strong>Local Start Time APJC (24hr) ({DateTime.fromISO(session.sessionStartAPJC).zoneName}):</strong> {DateTime.fromISO(session.sessionStartAPJC).hour.toLocaleString("en-US", {minimumIntegerDigits:2})}:{DateTime.fromISO(session.sessionStartAPJC).minute.toLocaleString("en-US", {minimumIntegerDigits:2})} / April {DateTime.fromISO(session.sessionStartAPJC).day}
              <br />
              <ICalendarLink filename={"AWSTIPS2022 - " + session.sessionTitle + ".ics"} event={this.getMeeting(session.sessionTitle, "breakout", "apjc")} >
                <button className="button is-black is-small is-responsive">
                  <span><strong>Add APJC Session to Calendar</strong></span>
                </button>
              </ICalendarLink>
              <br />
              <strong>Presenter(s):</strong> {session.sessionHostAMER}
              <br />
              <strong>Domain:</strong> {session.sessionDomain}
              <br />
              <strong>Session Type:</strong> {session.sessionType}
              <br />
              <br />
              <strong>Abstract:</strong> {session.sessionAbstract}
              <br />
              <br />
              {DateTime.now().toUTC().setZone('UTC-5') >= DateTime.fromISO(session.sessionStartAMER).toUTC().setZone('UTC-5').minus({ minutes: 30000 }) || DateTime.now().toUTC().setZone('UTC+1') >= DateTime.fromISO(session.sessionStartAMER).toUTC().setZone('UTC+1').minus({ minutes: 30000 }) || DateTime.now().toUTC().setZone('UTC+8') >= DateTime.fromISO(session.sessionStartAMER).toUTC().setZone('UTC+8').minus({ minutes: 30000 })?  
                            <div className="buttons is-centered">
                            <p>
                          <a href={session.sessionWebexLinkAMER} target="_blank" rel="noreferrer">
                          <button className="button is-link is-responsive">
                            <span className="icon is-small">
                              <i className="fas fa-paper-plane"></i>
                            </span>
                            <span>AMER Session</span>
                          </button>
                          </a>
                          </p>
            
                          <p>
                          <a href={session.sessionWebexLinkEMEA} target="_blank" rel="noreferrer">
                          <button className="button is-primary is-responsive">
                            <span className="icon is-small">
                              <i className="fas fa-paper-plane"></i>
                            </span>
                            <span>EMEA Session</span>
                          </button>
                          </a>
                          </p>
            
                          <p>
                          <a href={session.sessionWebexLinkAPJC} target="_blank" rel="noreferrer">
                          <button className="button is-danger is-responsive">
                            <span className="icon is-small">
                              <i className="fas fa-paper-plane"></i>
                            </span>
                            <span>APJC Session</span>
                          </button>
                          </a>
                          </p>
                          </div>
                          :
                          <button className="button is-warning">
                            <span className="icon is-small">
                              <i className="fas fa-paper-plane"></i>
                            </span>
                            <span>Available 30 minutes before session</span>
                          </button>
          }
            </div> 
            : null
          ))}
          </TabPanel>

          <TabPanel> 
            <div className="notification is-black">
              <strong><p className="is-size-4">TIPS 2022 - Day 2 - ATP Focused Sessions (April 6)</p></strong>
            </div>
            {Agenda.sessions.map((session) => (
              session.sessionDayEMEA === 2 ?           
              <div className="notification is-link is-light" key={session.sessionTitle}> 
              <p className="is-size-5"><strong>{session.sessionTitle}</strong></p>
              <br />
              <strong>Duration:</strong> {session.sessionDuration} hours
              <br />
              <strong>Start Time (AMER UTC-5 / EMEA UTC+1 / APJC UTC+8):</strong> {DateTime.fromISO(session.sessionStartAMER).toUTC().setZone('UTC-5').hour.toLocaleString("en-US", {minimumIntegerDigits:2})}:{DateTime.fromISO(session.sessionStartAMER).toUTC().setZone('UTC-5').minute.toLocaleString("en-US", {minimumIntegerDigits:2})} / April {DateTime.fromISO(session.sessionStartAMER).toUTC().setZone('UTC-5').day}
              <br />
              <strong>Local Start Time AMER (24hr) ({DateTime.fromISO(session.sessionStartAMER).zoneName}):</strong> {DateTime.fromISO(session.sessionStartAMER).hour.toLocaleString("en-US", {minimumIntegerDigits:2})}:{DateTime.fromISO(session.sessionStartAMER).minute.toLocaleString("en-US", {minimumIntegerDigits:2})} / April {DateTime.fromISO(session.sessionStartAMER).day}
              <br />
              <ICalendarLink filename={"AWSTIPS2022 - " + session.sessionTitle + ".ics"} event={this.getMeeting(session.sessionTitle, "breakout", "amer")} >
                <button className="button is-black is-small is-responsive">
                  <span><strong>Add AMER Session to Calendar</strong></span>
                </button>
              </ICalendarLink>
              <br />
              <strong>Local Start Time EMEA (24hr) ({DateTime.fromISO(session.sessionStartEMEA).zoneName}):</strong> {DateTime.fromISO(session.sessionStartEMEA).hour.toLocaleString("en-US", {minimumIntegerDigits:2})}:{DateTime.fromISO(session.sessionStartEMEA).minute.toLocaleString("en-US", {minimumIntegerDigits:2})} / April {DateTime.fromISO(session.sessionStartEMEA).day}
              <br />
              <ICalendarLink filename={"AWSTIPS2022 - " + session.sessionTitle + ".ics"} event={this.getMeeting(session.sessionTitle, "breakout", "emea")} >
                <button className="button is-black is-small is-responsive">
                  <span><strong>Add EMEA Session to Calendar</strong></span>
                </button>
              </ICalendarLink>
              <br />
              <strong>Local Start Time APJC (24hr) ({DateTime.fromISO(session.sessionStartAPJC).zoneName}):</strong> {DateTime.fromISO(session.sessionStartAPJC).hour.toLocaleString("en-US", {minimumIntegerDigits:2})}:{DateTime.fromISO(session.sessionStartAPJC).minute.toLocaleString("en-US", {minimumIntegerDigits:2})} / April {DateTime.fromISO(session.sessionStartAPJC).day}
              <br />
              <ICalendarLink filename={"AWSTIPS2022 - " + session.sessionTitle + ".ics"} event={this.getMeeting(session.sessionTitle, "breakout", "apjc")} >
                <button className="button is-black is-small is-responsive">
                  <span><strong>Add APJC Session to Calendar</strong></span>
                </button>
              </ICalendarLink>
              <br />
              <strong>Presenter(s):</strong> {session.sessionHostAMER}
              <br />
              <strong>Domain:</strong> {session.sessionDomain}
              <br />
              <strong>Session Type:</strong> {session.sessionType}
              <br />
              <br />
              <strong>Abstract:</strong> {session.sessionAbstract}
              <br />
              <br />
              {DateTime.now().toUTC().setZone('UTC-5') >= DateTime.fromISO(session.sessionStartAMER).toUTC().setZone('UTC-5').minus({ minutes: 30000 }) || DateTime.now().toUTC().setZone('UTC+1') >= DateTime.fromISO(session.sessionStartAMER).toUTC().setZone('UTC+1').minus({ minutes: 30000 }) || DateTime.now().toUTC().setZone('UTC+8') >= DateTime.fromISO(session.sessionStartAMER).toUTC().setZone('UTC+8').minus({ minutes: 30000 })?  
                            <div className="buttons is-centered">
                            <p>
                          <a href={session.sessionWebexLinkAMER} target="_blank" rel="noreferrer">
                          <button className="button is-link is-responsive">
                            <span className="icon is-small">
                              <i className="fas fa-paper-plane"></i>
                            </span>
                            <span>AMER Session</span>
                          </button>
                          </a>
                          </p>
            
                          <p>
                          <a href={session.sessionWebexLinkEMEA} target="_blank" rel="noreferrer">
                          <button className="button is-primary is-responsive">
                            <span className="icon is-small">
                              <i className="fas fa-paper-plane"></i>
                            </span>
                            <span>EMEA Session</span>
                          </button>
                          </a>
                          </p>
            
                          <p>
                          <a href={session.sessionWebexLinkAPJC} target="_blank" rel="noreferrer">
                          <button className="button is-danger is-responsive">
                            <span className="icon is-small">
                              <i className="fas fa-paper-plane"></i>
                            </span>
                            <span>APJC Session</span>
                          </button>
                          </a>
                          </p>
                          </div>
                          :
                          <button className="button is-warning">
                            <span className="icon is-small">
                              <i className="fas fa-paper-plane"></i>
                            </span>
                            <span>Available 30 minutes before session</span>
                          </button>
          }
            </div> 
            : null
          ))}
          </TabPanel> 
 </Tabs>
        </div>
        </section>
      </Fragment>
    );
  }

}
